<template>
  <div class="map-wrap container">
    <div class="map-ct">
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';

export default {
  name: 'MapView',
  setup() {
    const map = ref(null);
    const markers = ref([]);

    onMounted(async () => {
      map.value = L.map('map', {
        center: [60, 10],
        zoom: 3,
        zoomControl: false,
        maxZoom: 5,
        minZoom: 3,
        attributionControl: false
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        noWrap: true
      }).addTo(map.value);

      await fetchData();

      markers.value.forEach(markerData => {
        const marker = L.marker([markerData.latitude, markerData.longitude]).addTo(map.value);
        marker.bindPopup(markerData.name);
      });
    });

    async function fetchData() {
      try {
        const response = await axios.get('/page/home');
        markers.value = response.data.homeMapItems;
      } catch (error) {
        console.error('Error fetching data(map):', error);
      }
    }

    return { map };
  }
};
</script>

<style scoped>
#map {
  height: 600px;
  background: #ABD3DF;
  border-radius: 12px;
}
.map-ct {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(60px);
  padding: 12px;
}
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Set the background to transparent */
}
.map-wrap {
  padding-bottom: 80px;
}
@media (max-width: 960px) {
  #map {
    height: 400px;
    border-radius: 8px;
  }
  .map-wrap {
    padding-bottom: 50px !important;
  }
  .map-ct {
    padding: 8px;
    border-radius: 8px;
  }
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PlatformPage from "../views/platform/PlatformPage.vue";
import PlatformInfo from "../components/platformInfo.vue";
import PlatformComponent from "../components/platformComponent.vue";
import FavoritePrograms from "../views/platform/FavoritePrograms.vue";
import findPrograms from "@/views/newPlatform/findPrograms.vue";
import Programs from "../views/platform/Programs.vue";
import ProgramSlug from "../views/platform/ProgramSlug.vue";
import ourProducts from "../views/platform/ourProducts.vue";
import ourServices from "../views/platform/ourServices.vue";
import Cabinet from "../views/platform/Cabinet.vue";
import usefulPage from "../views/platform/usefulPage.vue";
import loginPage from "../views/login/loginPage.vue";
import registrationPage from "../views/login/registrationPage.vue";
import favouritePrograms from "@/views/newPlatform/favouritePrograms.vue";
import basketPage from "../views/platform/basketPage.vue";
import ResetPage from "../views/platform/ResetPage.vue"
import indexPlatform from "@/views/newPlatform/index.vue";
import inProgram from "@/views/newPlatform/inProgram.vue";
import admission from "@/views/newPlatform/admission.vue";
import schools from "@/views/newPlatform/schools.vue";
import intGrants from "@/views/newPlatform/intGrants.vue";
import premium from "@/views/newPlatform/premium.vue";
import useful from "@/views/newPlatform/useful.vue";
import curatorIndex from "@/views/curator/index.vue";
import users from "@/views/curator/users.vue";
import programs from "@/views/curator/programs.vue";
import i18n from "@/i18n.js";
import userView from "@/views/curator/userView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/curator",
    name: "curator",
    component: curatorIndex,
  },
  {
    path: "/curator/users",
    name: "users",
    component: users,
  },
  {
    path: "/curator/programs",
    name: "programs",
    component: programs,
  },
  {
    path: "/curator/users/:slug",
    name: "userView",
    component: userView,
  },
  {
    path: "/testPlatform",
    name: "test",
    component: indexPlatform,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/admission",
    name: "admission",
    component: admission,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/premium",
    name: "premium",
    component: premium,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/intGrants",
    name: "intGrants",
    component: intGrants,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/useful",
    name: "useful",
    component: useful,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatform/schools",
    name: "schools",
    component:schools,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testFavourite",
    name: "testFavourite",
    component: favouritePrograms,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatformFind",
    name: "testFind",
    component: findPrograms,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/testPlatformFind/:id",
    name: "testProgram",
    component: inProgram,
    meta: {
      isPlatform: true
    }
  },
  {
    path: "/about",
    name: "aboutView",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/price",
    name: "priceView",
    component: () => import("../views/PriceView.vue"),
  },
  {
    path: "/grants",
    name: "grantsView",
    component: () => import("../views/GrantsView.vue"),
  },
  {
    path: "/grants/:id",
    name: "grantsInView",
    component: () => import("../views/GrantsInView.vue"),
  },
  {
    path: "/career",
    name: "careerView",
    component: () => import("../views/CareerView.vue"),
    meta: {
      title: i18n.global.t(`career`),
      // title: "Карьерное менторство",
    },
  },
  {
    path: "/useful",
    name: "usefulView",
    component: () => import("../views/usefulView.vue"),
  },
  {
    path: "/blogs",
    name: "blogsView",
    component: () => import("../views/blogsView.vue"),
    meta: {
      title: i18n.global.t(`blog`),
      // title: "Блог",
    },
  },
  {
    path: "/blogInfo/:slug",
    name: "blogInfo",
    component: () => import("../components/blogInfo.vue"),
  },
  {
    path: "/develop",
    name: "developView",
    component: () => import("../views/developView.vue"),
    meta: {
      title: i18n.global.t(`dev`),
      // title: "Развитие талантов",
    },
  },
  {
    path: "/faq",
    name: "faqView",
    component: () => import("../views/faqView.vue"),
    meta: {
      title: i18n.global.t(`faq`),
      // title: "Вопрос и ответ",
    },
  },
  {
    path: "/login",
    name: "login",
    component: loginPage
  },
  {
    path: "/registration",
    name: "registration",
    component: registrationPage
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPage
  },
  {
    path: "/platform/programs",
    name: "platform-page",
    component: PlatformPage,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/platform/favorite",
        component: FavoritePrograms
      },
      {
        path: "/platform/programs",
        component: Programs
      },
      {
        path: "/platform/programs/:id",
        component: ProgramSlug,
      },
      {
        path: "/platform/our-products",
        component: ourProducts
      },
      {
        path: "/platform/cabinet",
        component: Cabinet
      },
      {
        path: "/platform/useful",
        component: usefulPage
      },
      {
        path: "/platform/our-services",
        component: ourServices
      },
      {
        path: "/platform/basket",
        component: basketPage
      }
    ]
  },
  {
    path: "/platformInfo",
    name: "platformInfo",
    component: PlatformInfo,
  },
  {
    path: "/platform-site",
    name: "platformComponent",
    component: PlatformComponent,
  },
];

const router = createRouter({ 
  history: createWebHistory(process.env.BASE_URL), 
  routes, 
  scrollBehavior(to) { 
    // Проверяем, есть ли у нас хэш-адрес в объекте to 
    if (to.hash) { 
       
        const el = document.querySelector(to.hash); 
        if (el) { 
        const scrollTop = el.getBoundingClientRect().top + window.pageYOffset - 85; 
        window.scrollTo({ 
          top: scrollTop, 
          behavior: 'smooth' 
        }); 
        return null; 
      } 
 
    } else { 
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' }) 
      // return { top: 0 } 
    } 
  } 
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
    }
    else {
      next({ name: "home" });
    }
  }
  else {
    next();
  }
});

export default router;

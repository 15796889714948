<template>
  <div class="tab-items m-none">
    <ul class="tab-list">
      <li
        v-for="tab in tabItems"
        :key="tab.id"
        class="tab-item tab-link tab-title"
        :class="{ active: tab === selected }"
        @click="setTab(tab)"
      >
          {{ tab }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tabItems: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  methods: {
    setTab(tab) {
      this.$emit("setTab", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: #FFFFFF !important;
  background: #11415D !important;
  transition: 0.4s;
}
ul {
  padding-left: 0 !important;
}
.tab {
  &-list {
    display: flex;
    justify-content: space-between;
  }

  &-item {
      list-style: none;
  }

  &-items {
    max-width: 725px;
    width: 100%;
    height: 52px;
    padding: 5px;
    background: #F5F7FB;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);

  }

  &-link {
    width: 24%;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    text-align: center;
    background: #FFFFFF;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.10);
    padding: 8px;
    border-radius: 8px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 15px;
    }
  }
}
@media (min-width: 960px) and (max-width: 1440px) {
  .tab {
    &-link {
      font-size: 15px;
      padding: 8px 4px;
    }
  }
}
</style>
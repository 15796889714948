<template>
  <div class="desctop">
    <div :class="[isTransparent ? 'transparent' : '', 'header']">
      <div class="container">
        <div class="header__main flex-row-between-center">
          <div class="header__main-left d-flex-center">
            <router-link to="/">
              <div class="header__logo d-flex-center">
                <!-- <img src="@/assets/logo.svg" /></router-link> -->
                <img :class="[isTransparent ? '' : 'hide', 'header__logo-img']" src="@/assets/logo_red_white.png" />
                <img :class="[isTransparent ? 'hide' : '', 'header__logo-img']" src="@/assets/logo-blue-left1.png" />
                <div class="header__logo-left">
                  <h1 class="header__logo-left-first r24-700">
                    MENTOR<span class="header__logo-left-first2 b24-700">ME</span>
                  </h1>
                </div>
              </div>
            </router-link>
            <div class="header__language d-flex">
              <!-- <select name="select" class="header__language-type" v-model="selectedOption" @change="handleChange($event)">
                <option value="kz" @click="getLang(languages[1])">KZ</option>
                <option value="ru" @click="getLang(languages[0])">RU</option>
                <option value="en" @click="getLang(languages[2])">EN</option>
              </select> -->
              <div class="dropdown" @click="toggleDropdown1">
                <div class="header__language-type dropdown-desk">
                  <p style="text-transform: uppercase;">{{ selectedOption }}</p>
                  <img :class="[isTransparent ? '' : 'hide']" src="@/assets/images/arr-down.png" :style="dropdownVisible1 ? 'rotate: 180deg' : 'rotate: 0deg'" alt="">
                  <img :class="[isTransparent ? 'hide' : '']" src="@/assets/images/arr-down-gray.png" :style="dropdownVisible1 ? 'rotate: 180deg' : 'rotate: 0deg'" style="width: 20px; margin-left: 6px;" alt="">
                </div>
                <div class="dropdown-content desktop" style="width: 52px;" v-show="dropdownVisible1" @click="hideDropdown1">
                  <div  @click="handleChange($event)" class="dropdown-content__wrap">
                    <button class="dropdown-content-btn" :class="{'activeBtn' : this.selectedOption == 'kz'}" value="kz">KZ</button>
                    <button class="dropdown-content-btn" :class="{'activeBtn' : this.selectedOption == 'en'}" value="en">EN</button>
                    <button class="dropdown-content-btn" :class="{'activeBtn' : this.selectedOption == 'ru'}" value="ru">RU</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header__menu d-flex-center">
            <div class="header__menu-main">
              <router-link class="header__menu-link" to="/">{{ this.$t("home") }}</router-link>
            </div>
            <div class="header__menu-main">
              <router-link class="header__menu-link" to="/about">{{ this.$t("about") }}</router-link>
            </div>
            <div class="header__menu-main">
              <router-link class="header__menu-link" to="/grants" :exact-active-class="'active-link'"
                :class="$route.name === 'grantsInView' ? 'router-link-active' : ''">{{ this.$t("grants") }}</router-link>
            </div>
            <div class="header__menu-main">
              <router-link class="header__menu-link" to="/useful">{{ this.$t("plat") }}</router-link>
            </div>

            <div class="header__menu-main">
              <router-link class="header__menu-link" to="/price">{{ this.$t("price") }}</router-link>
            </div>
            <div class="dropdown" @click="toggleDropdown">
              <div class="header-other">
                <div class="header__menu-link1">
                  <p v-if="!$route.meta.title">{{ this.$t("other") }}</p>
                  <p style="color: #000;" v-else>{{ $route.meta.title }}</p>
                  <div v-if="$route.meta.title == this.$t('career')"
                    :class="{ 'activeLink': $route.meta.title == this.$t('career') }"></div>
                  <div v-if="$route.meta.title == this.$t('dev')"
                    :class="{ 'activeLink': $route.meta.title == this.$t('dev') }"></div>
                  <div v-if="$route.meta.title == this.$t('faq')"
                    :class="{ 'activeLink': $route.meta.title == this.$t('faq') }"></div>
                  <div v-if="$route.meta.title == this.$t('blog')" :class="{ 'activeLink': $route.meta.title == this.$t('blog') }"></div>
                </div>
                <img :class="[isTransparent ? '' : 'hide']" :style="dropdownVisible ? 'rotate: 180deg' : 'rotate: 0deg'" src="@/assets/images/arr-down.png" style="width: 32px; height: 32px;" alt="arrow">
                <img :class="[isTransparent ? 'hide' : '']" :style="dropdownVisible ? 'rotate: 180deg' : 'rotate: 0deg'" src="@/assets/images/arr-gray.png" style="width: 20px; height: 20px; margin-left: 6px;" alt="arrow">
              </div>
              <div class="dropdown-content1" v-show="dropdownVisible" @click="hideDropdown">
                <div class="" style="border-radius: 14px;">
                  <router-link class="dropdown-content1-item" to="/career">
                    <p style="border-radius: 14px 14px 0px 0px;">{{ $t("career") }}</p>
                  </router-link>
                  <router-link class="dropdown-content1-item" to="/develop">
                    <p>{{ $t("dev") }}</p>
                  </router-link>
                  <router-link class="dropdown-content1-item" to="/faq">
                    <p>{{ $t("faq") }}</p>
                  </router-link>
                  <router-link class="dropdown-content1-item" to="/blogs">
                    <p style="border-radius: 0px 0px 14px 14px;">{{ $t("blog") }}</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="header__sign">
            <router-link to="/login" class="header__sign-in">{{ this.$t('sign1') }}</router-link>
            <span class="header__sign-line"></span>
            <router-link to="/registration" class="header__sign-up">
              {{ this.$t('login') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile">
    <div :class="[isTransparent ? 'transparent' : '', 'HeaderMobile']">
      <router-link to="/">
        <div class="HeaderMobile__left">
          <img :class="[isTransparent ? '' : 'hide', 'header__logo-img']" src="@/assets/logo_red_white.png" />
          <img :class="[isTransparent ? 'hide' : '', 'header__logo-img']" src="@/assets/logo-blue-left1.png" />
          <h1 class="header__logo-left-first r24-700">
            MENTOR<span class="header__logo-left-first2">ME</span>
          </h1>
        </div>
      </router-link>
      <label  class="burger2" for="burger2">
        <input @change="burgerMenuActive = !burgerMenuActive" type="checkbox" id="burger2">
        <span></span>
        <span></span>
        <span></span>
      </label>

    </div>
    <transition name="bounce">
      <div v-if="burgerMenuActive" :class="{'hidden' : burgerMenuActive}" class="headerMobileContent">
        <div class="headerMobileContent__top">
          <div class="headerMobileContent__top__exit">
            <!-- <img src="@/assets/images/Vector.svg" @click="burgerMenuActive = !burgerMenuActive" /> -->
            <router-link to="/">
              <div class="headerMobileContent__top__exit__icon">
                <img style="width: 28px; height: 34px;" class="" src="@/assets/logo_red_white.png" />
                <div class="">
                  <h1 class="header__logo-left-first r24-700">
                    MENTOR<span class="header__logo-left-first2" style="
                      color: white;
                      font-weight: 700;
                      font-size: 24px;
                      margin-bottom: 0px;
                    ">ME</span>
                  </h1>
                </div>
              </div>
            </router-link>
          </div>
          <div class="headerMobileContent__top__languages">
            <select name="select" class="header__language-type" v-model="selectedOption" @change="handleChange($event)">
              <option value="kz" @click="getLang(languages[1])">KZ</option>
              <option value="ru" @click="getLang(languages[0])">RU</option>
              <option value="en" @click="getLang(languages[2])">EN</option>
            </select>
          </div>
        </div>
        <div class="headerMobileContent__bot">
          <div class="line"></div>
          <router-link to="/login">
            <button @click="$store.state.openSignModal = true">
              {{ this.$t('sign1') }} / {{ this.$t('login') }}
            </button>
          </router-link>
          <div class="line"></div>
          <router-link to="/">
            <p :class="{ activeClass: $route.path == '/' }">
              {{ this.$t("home") }}
            </p>
          </router-link>
          <div class="line"></div>
          <router-link to="/about">
            <p style :class="{ activeClass: $route.path == '/about' }">{{ this.$t("about") }}</p>
          </router-link>
          <div class="line"></div>
          <router-link to="/grants">
            <p :class="{ activeClass: $route.path == '/grants' }">{{ this.$t("grants") }}</p>
          </router-link>
          <div class="line"></div>
          <router-link to="/useful">
            <p :class="{ activeClass: $route.path == '/useful' }">{{ this.$t("plat") }}</p>
          </router-link>
          <div class="line"></div>
          <router-link to="/price">
            <p :class="{ activeClass: $route.path == '/price' }">{{ this.$t("price") }}</p>
          </router-link>
          <div class="line"></div>
          <div class="headerMobileContent__bot__others" @click="openOthers = !openOthers">
            <p>{{ this.$t("other") }}</p>
            <img :class="openOthers ? 'transf' : ''" src="@/assets/OpenOthers.svg" />
          </div>
          <div class="line"></div>
        </div>

        <div v-if="openOthers" class="headerMobileContent__dropdown">
          <router-link to="/career">
            <p :class="{ activeClass: $route.path == '/career' }"
              style="text-decoration: none; color: rgba(255, 255, 255, 0.54)">
              {{ $t("career") }}
            </p>
          </router-link>
          <div class="line"></div>
          <router-link to="/develop">
            <p :class="{ activeClass: $route.path == '/develop' }"
              style="text-decoration: none; color: rgba(255, 255, 255, 0.54)">
              {{ $t("dev") }}
            </p>
          </router-link>
          <div class="line"></div>
          <router-link to="/faq">
            <p :class="{ activeClass: $route.path == '/faq' }"
              style="text-decoration: none; color: rgba(255, 255, 255, 0.54)">
              {{ $t("faq") }}
            </p>
          </router-link>
          <div class="line"></div>
          <router-link to="/blogs">
            <p :class="{ activeClass: $route.path == '/blogs' }"
              style="text-decoration: none; color: rgba(255, 255, 255, 0.54)">
              {{ $t("blog") }}
            </p>
          </router-link>
        </div>

      </div>
    </transition>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      currentSection: "Другое",
      burgerMenuActive: false,
      openOthers: true,
      dropdownVisible: false,
      dropdownVisible1: false,
      selectedOption: "ru",
      languages: [
        { id: 1, value: "ru", title: "RU" },
        { id: 2, value: "kz", title: "KZ" },
        { id: 3, value: "en", title: "EN" },
      ],
      visible: false,
      subtitle: "Другое",
      otherTitles: {
        career: "Карьерное менторство",
      },
    };
  },
  mounted() {
    this.selectedOption = localStorage.getItem("lang") || "ru";
  },
  props: ["isTransparent"],
  methods: {
    changeSection(value) {
      this.currentSection = value;
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    toggleDropdown1() {
      this.dropdownVisible1 = !this.dropdownVisible1;
    },
    hideDropdown() {
      this.dropdownVisible = false;
    },
    hideDropdown1() {
      this.dropdownVisible1 = false;
    },
    // openRegistrModal(value) {
    //   this.visible = value;
    //   console.log(value);
    // },
    ...mapMutations(["SET_LANGUAGE"]),
    getLang(option) {
      this.$i18n.locale = option;
      this.SET_LANGUAGE(option.value);
      this.$forceUpdate();
      location.reload();
    },
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.hidden {
  overflow-y: hidden;
}
.mobile {
  position: relative;
  z-index: 99999;
}
.activeBtn {
  color: #FD2D2ACC !important;
}
// .active-link {
//   color: #000 !important;

//   &::after {
//     content: "";
//     visibility: visible;
//     transform: scaleX(1);
//     bottom: -5px;
//     opacity: 1;
//     left: 0;
//     background-color: #fd2d2a;
//     border-radius: 2px;
//     width: 18px;
//     height: 2px;
//   }
// }

.activeLink {
  background-color: #fd2d2a;
  width: 18px;
  height: 2px;
  top: 23px;
  position: absolute;
}

.dropdown-content {
  &:hover {
    .last-link {
      border-bottom: 1px solid #000 !important;
    }
  }
}

.HeaderMobile {
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;

  &__left {
    display: flex;
    gap: 9px;
    align-items: center;
  }

  &__right {
    cursor: pointer;
  }
}

.buttonRu {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px 0px 0px 8px;
  border: none;
  height: 40px;
  width: 49%;
  color: rgba(255, 255, 255, 0.54);
  font-weight: 500;
  font-size: 20px;
}

.buttonKz {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px 8px 8px 0px;
  border: none;
  height: 40px;
  width: 49%;
  color: rgba(255, 255, 255, 0.54);
  font-weight: 500;
  font-size: 20px;
}

.line {
  background: rgba(255, 255, 255, 0.1);
  height: 1px;
}

.bounce-enter-active {
  animation: bounce-in .7s;
}

.bounce-leave-active {
  animation: bounce-in .7s reverse;
}

@keyframes bounce-in {
  0% {
    right: -100%;
    opacity: 0.5;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

.headerMobileContent {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: #11415d;
  z-index: 999;
  padding-top: 13px;
  padding-right: 24px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow: auto;
  z-index: 3;
  padding-bottom: 100px;
  // right: 0;

  &__top {
    display: flex;
    // flex-direction: column;
    gap: 18px;

    &__exit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 8%;
      }

      &__icon {
        display: flex;
        align-items: center;
        gap: 9px;

        img {
          width: 100%;
        }
      }
    }

    &__languages {
      display: flex;
      align-items: center;

      p {
        color: rgba(255, 255, 255, 0.54);
        font-size: 24px;
        font-weight: 500;
        margin-right: 14px;
      }

      select {
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.10);
        padding: 8px 18px;
        width: 154px;
        appearance: none;
        background: url('@/assets/images/arr-down.png') 96% / 15% no-repeat;
      }
    }
  }

  &__bot {
    display: flex;
    flex-direction: column;
    gap: 13px;

    &__others {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 20px;
        height: 14px;
      }

      .transf {
        transform: rotate(180deg);
        transition: all .5s ease-out;
      }
    }

    & button {
      width: 100%;
      background: #ffffff;
      border-radius: 18px;
      border: none;
      height: 40px;
      color: #11415d;
    }

    p {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.54);
      font-size: 24px;
    }
  }

  &__dropdown {

    color: white;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    flex-direction: column;

    // gap: 4px;
    @media (max-width: 576px) {
      gap: 13px;
      transition: all .5s ease-in;

      p {
        margin-left: 30px;

      }

    }
  }
}
.desktop {
  &::before {
    display: none;
  }
  .dropdown-content-btn {
    transition: 0.4s;
    padding: 0;
    &:hover {
      transition: 0.4s;
      color: #FD2D2ACC;
    }
  }
}
.dropdown {
  position: relative;
  display: inline-block;
  &-desk {
    display: flex;
    align-items: center;
    img {
      transition: 0.4s all ease;
      width: 32px;
    }
  }
  // padding-top: 20px;
  .other-content {
    &::before {
      display: none;
    }
  }
  &-content {
    display: block;
    position: absolute;
    top: 35px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    width: clamp(210px, 118%, 420px);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 223;
    border-radius: 14px;
    text-align: center;
    &-btn {
      border: none;
      background-color: transparent;
      // border-radius: 14px;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      padding: 2px 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      margin: 6px 2px 6px 0;
    }
    &-item {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: rgba(0, 0, 0, 0.54);
        background-color: #ffffff !important;
        font-weight: 500;
        transition: all 0.6s;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;

        &:last-child {
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
        }
      }
    }

    &::before {
      position: absolute;
      content: "";
      height: 10px;
      width: 10px;
      background: #ffffff;
      left: 20px;
      top: -5px;
      transform: rotate(45deg);
    }

    &-item {
      padding: 12px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #ddd;
      }
    }
  }
  &-content1 {
    display: block;
    position: absolute;
    top: 35px;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    width: 250px;
    z-index: 223;
    border-radius: 14px;
    text-align: center;
    .router-link-active {
      color: rgba(0, 0, 0, 0.54);
        &:hover {
          color: #FFF !important;
        }
      }
    &-item {
      color: rgba(0, 0, 0, 0.54);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      transition: all ease 0.2s;
      &:hover {
        color: #FFF !important;
        p {
          background: rgba(253, 45, 42, 0.80) !important;
        }
      }
    }
    p {
      padding: 12px 0;
      transition: all ease 0.2s;
    }
    a {
      &:not(:last-child) {
        p {
          border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        }
      }
    }
  }
}

.dropbtn {
  transition: 0.4s;
  background-color: #f1f1f1;
  // width: 192px;
}
.hide {
  display: none;
}
.transparent.header {

  // background-image: url(@/assets/images/mainPage/main-banner.png);
  .router-link-active {
    color: white !important;

    &::after {
      content: "";
      visibility: visible;
      transform: scaleX(1);
      bottom: -5px;
      opacity: 1;
      left: 0;
      background-color: #fd2d2a;
      border-radius: 2px;
      width: 18px;
      height: 2px;
    }
  }

  .header__language {
    &-type {
      background: transparent;
      color: rgba(255, 255, 255, 0.54);
      cursor: pointer;
      option {
        color: black;
      }
    }
  }

  .header__logo {
    &-left {
      &-first2 {
        color: #ffffff;
      }
    }
  }

  .header__menu {
    &-link, &-link1 {
      color: rgba(255, 255, 255, 0.54);

      &:hover {
        color: white;
      }
    }
  }

  .header__sign {
    border: 1px solid rgba(255, 255, 255, 0.54);
    cursor: pointer;

    &-in {
      color: rgba(255, 255, 255, 0.54);
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        color: #FFF;
      }
    }

    &-line {
      border-right: 1px solid rgba(255, 255, 255, 0.54);
    }

    &-up {
      color: rgba(255, 255, 255, 0.54);
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        color: #FFF;
      }
    }
  }
}

.header {
  position: absolute;
  z-index: 998;
  top: 0;
  width: 100%;
  background-color: transparent;
  margin-top: 24px;
  &-other {
    display: flex;
    align-items: center;
    margin-right: 62px;
    img {
      transition: all 0.4s;
    }
    .hide {
        display: none;
      }
  }
  .router-link-active {
    color: black !important;

    &::after {
      content: "";
      visibility: visible;
      transform: scaleX(1);
      bottom: -5px;
      opacity: 1;
      left: 0;
      background-color: #fd2d2a;
      border-radius: 2px;
      width: 18px;
      height: 2px;
    }
  }

  &__logo {
    &-img {
      width: 29px;
      height: 35px;
      margin-right: 10px;
      object-fit: contain;
      // image-rendering: pixelated;
      &.hide {
        display: none;
      }
    }

    &-left {
      &-first {
        margin-bottom: 0px;
      }

      &-first2 {
        color: #11415d;
      }
    }
  }

  // background-image: url(@/assets/images/mainPage/main-banner.png);
  &__language {
    padding-left: 28px;

    &-type {
      border: none;
      background: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.54);

      @media (max-width: 576px) {
        // color: black!important;
        background: #11415d !important;

      }
    }
  }

  &__menu {

    // position: relative;
    &-main {
      position: relative;
    }
    &-link1 {
      // margin-right: 62px;
      text-decoration: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;
      // display: flex;
      // align-items: center;
    }
    &-link {
      margin-right: 62px;
      text-decoration: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.54);

      &:hover {
        color: black;
      }

      &::after {
        content: "";
        position: absolute;
        visibility: hidden;
        transform: scaleX(0);
        opacity: 0;
        bottom: 0;
        left: 0;
        transition: all 0.4s ease-in-out;
        background-color: #fd2d2a;
        border-radius: 2px;
        width: 18px;
        height: 2px;
      }

      &:hover::after {
        visibility: visible;
        bottom: -5px;
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }

  &__sign {
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 8px;
    cursor: pointer;

    &-in {
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54);
      text-decoration: none;
      padding: 6px 12px;
      cursor: pointer;
    }

    &-line {
      border-right: 1px solid rgba(0, 0, 0, 0.54);
    }

    &-up {
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54);
      text-decoration: none;
      padding: 6px 12px;
      cursor: pointer;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .header {
    &__logo {
      &-left {
        &-first {
          font-size: 18px;
        }

        &-first2 {
          font-size: 18px;
        }
      }
    }

    &__sign {
      &-in {
        font-size: 14px;
      }

      &-up {
        font-size: 14px;
      }
    }

    &__menu {
      &-link, &-link1 {
        font-size: 13px;
        margin-right: 20px;
      }
    }

    &__language {
      padding-left: 10px;
    }
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .header {
    &__logo {
      &-left {
        &-first {
          font-size: 18px;
        }

        &-first2 {
          font-size: 18px;
        }
      }
    }

    &__sign {
      &-in {
        font-size: 14px;
      }

      &-up {
        font-size: 14px;
      }
    }

    &__menu {
      &-link {
        font-size: 13px;
        margin-right: 20px;
      }
    }

    &__language {
      padding-left: 10px;
    }
  }
}

@media (max-width: 990px) {
  .transparent.HeaderMobile {
    .header__logo {
      &-left {
        &-first2 {
          color: #ffffff;
        }
      }
    }
  }

  .HeaderMobile {
    &__logo {
      &-left {
        &-first2 {
          color: #11415d;
        }
      }
    }

    &__menu {
      &-link {
        display: none;
      }
    }

    &__language {
      &-type {
        display: none;
      }
    }

    &__sign {
      display: none;
    }
  }
}
.header__language {
  &-type {
    width: 68px !important;
  }
}
.activeLang {
  background: red;
  color: white;
}

.activeClass {
  color: red !important;
}
</style>

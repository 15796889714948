<template>
  <div class="head">
    <div class="container-wrap">
      <header class="head-wrap m_none">
        <div class="head-logo">
          <img src="@/assets/images/platform/logo.png" alt="" />
          <div class="head-logo-ai">AI Platform</div>
        </div>
        <div class="head-search">
          <img src="@/assets/images/platform/search-grey.svg" alt="" />
          <input
            :placeholder="this.$t('searchSite') + '...'"
            type="text"
            ref="search"
            v-model="search_input"
            @keyup="searchHandle($event)"
          />
        </div>
        <div class="head-right">
          <router-link class="basket" to="/platform/basket">
            <img
              src="@/assets/images/platform/logo/basket.svg"
              alt=""
              v-if="getBasket.length == 0"
            />
            <div v-else class="basket-active">
              <img src="@/assets/images/platform/logo/cart.svg" alt="" />
              <p>{{ getBasket.length }}</p>
            </div>
          </router-link>
          <div @click="logout" class="head-out px-16 fw600 gray">
            <img src="@/assets/images/platform/logout.svg" alt="" />
            {{ this.$t("logout") }}
          </div>
        </div>
      </header>
      <header class="head-mobile d_none">
        <a href="" class="logos">
          <!-- <img class="head-mobile__logo" src="@/assets/logo-blue-left.svg" alt=""> -->
          <img
            class="head-mobile__ai"
            src="@/assets/images/platform/header.png"
            alt=""
          />
        </a>
        <router-link class="basket" to="/platform/basket">
          <img
            src="@/assets/images/platform/logo/basket.svg"
            alt=""
            v-if="getBasket.length == 0"
          />
          <div v-else class="basket-active">
            <img src="@/assets/images/platform/logo/cart.svg" alt="" />
            <p>{{ getBasket.length }}</p>
          </div>
        </router-link>
        <div class="burger">
          <img
            v-if="!burgerMenuActive"
            @click="burgerMenuActive = true"
            style="width: 30px; height: 30px"
            src="@/assets/images/platform/logo/open.png"
            alt="burger"
          />
        </div>
      </header>
      <transition name="bounce">
        <div
          v-if="burgerMenuActive"
          :class="{ hidden: burgerMenuActive }"
          class="headerMobileContent"
        >
          <div class="headerMobileContent__top">
            <div class="headerMobileContent__top__exit">
              <router-link to="/">
                <div class="headerMobileContent__top__exit__icon">
                  <img
                    class=""
                    style="width: 28px; height: 34px"
                    src="@/assets/logo_red_white.png"
                  />
                  <div class="">
                    <h1
                      class="header__logo-left-first r24-700"
                      style="margin-bottom: 0"
                    >
                      MENTOR<span
                        class="header__logo-left-first2"
                        style="
                          color: white;
                          font-weight: 700;
                          font-size: 24px;
                          margin-bottom: 0px;
                        "
                        >ME</span
                      >
                    </h1>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="headerMobileContent__top__languages">
              <select
                name="select"
                class="headerMobileContent__language-type"
                v-model="selectedOption"
                @change="handleChange($event)"
              >
                <option value="kz" @click="getLang(languages[1])">KZ</option>
                <option value="ru" @click="getLang(languages[0])">RU</option>
                <option value="en" @click="getLang(languages[2])">EN</option>
              </select>
            </div>
            <img
              class="exit"
              v-if="burgerMenuActive"
              @click="burgerMenuActive = false"
              src="@/assets/images/platform/logo/close.png"
              alt=""
            />
          </div>
          <div class="headerMobileContent__bot">
            <div class="line"></div>
            <router-link
              @click="burgerMenuActive = false"
              to="/platform/programs"
            >
              <p>{{ $t("searchProgram") }}</p>
            </router-link>
            <div class="line"></div>
            <router-link
              @click="burgerMenuActive = false"
              to="/platform/favorite"
            >
              <p>{{ $t("fav") }}</p>
            </router-link>
            <div class="line"></div>
            <router-link
              @click="burgerMenuActive = false"
              to="/platform/cabinet"
            >
              <p>{{ $t("acc") }}</p>
            </router-link>
            <div class="line"></div>
            <router-link
              @click="burgerMenuActive = false"
              to="/platform/our-products"
            >
              <p>{{ $t("ourProd") }}</p>
            </router-link>
            <div class="line"></div>
            <router-link
              @click="burgerMenuActive = false"
              to="/platform/our-services"
            >
              <p>{{ $t("ourServ") }}</p>
            </router-link>
            <div class="line"></div>
            <router-link
              @click="burgerMenuActive = false"
              to="/platform/useful"
            >
              <p>{{ this.$t("use") }}</p>
            </router-link>
            <div class="line"></div>
            <div class="logout" @click="logout">
              <img src="@/assets/images/platform/logout-white.png" alt="" />
              <p>{{ this.$t("logout") }}</p>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      burgerMenuActive: false,
      search_input: "",
      isResult: false,
      selectedOption: "ru",
      languages: [
        { id: 1, value: "ru", title: "RU" },
        { id: 2, value: "kz", title: "KZ" },
        { id: 3, value: "en", title: "EN" },
      ],
    };
  },
  mounted() {
    this.selectedOption = localStorage.getItem("lang") || "ru";
    this.search_input = this.$route.query.search;
    console.log("this.$route.query.search", this.$route.query.search);
  },
  computed: {
    ...mapGetters(["getBasket", "UNI_PROGRAMS"]),
  },
  methods: {
    ...mapActions(["logoutUser", "GET_UNI_PROGRAMS"]),
    ...mapMutations(["SET_LANGUAGE"]),
    getLang(option) {
      this.$i18n.locale = option;
      this.SET_LANGUAGE(option.value);
      this.$forceUpdate();
      location.reload();
    },
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
    logout() {
      this.logoutUser();
      this.$router.push("/");
    },
    async searchHandle(e) {
      let res = e.target.value;
      if (res.length >= 3) {
        await this.GET_UNI_PROGRAMS({
          search: this.search_input,
          is_toefl: this.$route.query.is_toefl,
          is_ielts: this.$route.query.is_ielts,
          category_id: this.$route.query.category_id,
          sub_category_id: this.$route.query.sub_category_id,
          country_id: this.$route.query.country_id,
        }).then(() => {
          this.isResult = true;
          this.$router.push({
            path: `/platform/programs?search=`,
            query: {
              search: this.search_input,
              is_toefl: this.$route.query.is_toefl,
              is_ielts: this.$route.query.is_ielts,
              category_id: this.$route.query.category_id,
              sub_category_id: this.$route.query.sub_category_id,
              country_id: this.$route.query.country_id,
            },
          });
          // console.log(`asdasd:  /platform/programs?search=${this.search_input}`);
          // console.log(this.UNI_PROGRAMS);
        });
      } else {
        this.isResult = false;
        if (res == "") {
          this.GET_UNI_PROGRAMS();
          console.log("NEW PROGRAMS");
        }
      }
    },
  },
  watch: {
    $route() {
      this.burgerMenuActive = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/platform/main.scss";
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.hidden {
  overflow-y: hidden;
}
.bounce-leave-active {
  animation: bounce-in 0.7s reverse;
}
.logout {
  display: flex;
  align-items: center;
  img {
    width: 28px;
    height: 20px;
    margin-right: 8px;
  }
}
.basket {
  &-active {
    display: flex;
    position: relative;
    img {
      width: 33px !important;
      height: 34px !important;
    }
    p {
      position: absolute;
      font-size: 10px;
      line-height: 12px;
      color: #fff;
      top: 4px;
      right: 7px;
    }
  }
}
.headerMobileContent {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #11415d;
  z-index: 999;
  padding: 13px 10px 0 13px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow: auto;
  z-index: 1111;
  padding-bottom: 100px;
  // right: 0;
  .line {
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 1px;
  }
  &__top {
    position: relative;
    display: flex;
    // flex-direction: column;
    gap: 18px;
    .exit {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 5px;
    }

    &__exit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 8%;
      }

      &__icon {
        display: flex;
        align-items: center;
        gap: 9px;

        img {
          width: 100%;
        }
      }
    }

    &__languages {
      display: flex;
      align-items: center;
      p {
        color: rgba(255, 255, 255, 0.54);
        font-size: 24px;
        font-weight: 500;
        margin-right: 14px;
      }
      select {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.1);
        padding: 8px 18px;
        width: 86px;
        appearance: none;
        background: url("@/assets/images/arr-down.png") 96% / 43% no-repeat;
      }
    }
  }

  &__bot {
    display: flex;
    flex-direction: column;
    gap: 13px;

    & button {
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      border: none;
      height: 40px;
      color: #11415d;
    }

    p {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.54);
      font-size: 24px;
    }
  }

  &__dropdown {
    color: white;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    flex-direction: column;

    // gap: 4px;
    @media (max-width: 576px) {
      gap: 13px;
      transition: all 0.5s ease-in;

      p {
        margin-left: 30px;
      }
    }
  }
}
@keyframes bounce-in {
  0% {
    right: -100%;
    opacity: 0.5;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}
.head {
  background: #f5f7fb;
  padding: 20px 0;
  &-logo {
    display: flex;
    align-items: center;
    img {
      width: 170px;
      margin-right: 12px;
    }
    &-ai {
      font-size: 16px;
      color: #fff;
      border-radius: 5px;
      background: #11415d;
      padding: 2px 8px;
      // width: 138px;
      text-transform: uppercase;
    }
  }
  &-right {
    display: flex;
    .basket {
      margin-right: 42px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  &-search {
    position: relative;
    img {
      width: 18px;
      margin-left: 21px;
      position: absolute;
    }
    input {
      padding: 10px 0px 12px 54px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-style: italic;
      width: 627px;
    }
  }
  &-out {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      margin-right: 8px;
    }
  }
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 920px) and (max-width: 1470px) {
  .head {
    &-logo {
      img {
        width: 140px;
      }
    }
    &-search {
      margin-left: 1%;
      input {
        width: 527px;
      }
    }
  }
}
@media (max-width: 960px) {
  .head {
    background-color: #fff;
    padding: 18px 0;
    &-out {
      padding-left: 2rem;
    }
    &-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__logo {
        width: 28px;
        height: 34px;
        margin-right: 9px;
      }
      &__ai {
        width: 270px;
      }
      .logos {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>

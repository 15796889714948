<template>
  <div v-if="MAIN_PAGE">
  <HeaderMain is-transparent="true" />
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.svg" /> -->
    <MainComponent :MAIN_PAGE="MAIN_PAGE" />
    <Counter :MAIN_PAGE="MAIN_PAGE" />
    <History :MAIN_PAGE="MAIN_PAGE" />
    <div class="scholarships">
      <Scholarship :MAIN_PAGE="MAIN_PAGE" />
      <ScholarshipPlus :MAIN_PAGE="MAIN_PAGE" />
    </div>
    <Feedback :MAIN_PAGE="MAIN_PAGE" />
    <div class="programs">
      <Program :MAIN_PAGE="MAIN_PAGE" />
      <ProgramPlus :MAIN_PAGE="MAIN_PAGE" />
    </div>
    <CountactUs />
    <!-- <SignInModal />
    <RegistrModal /> -->
    <!-- <div class="aboutPage">

    </div> -->
  </div>
  <FooterMain />
  </div>
</template>

<script>
import HeaderMain from "@/components/layouts/HeaderMain.vue";
import FooterMain from "@/components/layouts/FooterMain.vue";
import History from "@/components/History.vue";
import MainComponent from "@/components/MainComponent.vue";
import Counter from "@/components/Counter.vue";
import Scholarship from "@/components/Scholarship.vue";
import ScholarshipPlus from "@/components/ScholarshipPlus.vue";
import Feedback from "@/components/Feedback.vue";
import Program from "@/components/Program.vue";
import ProgramPlus from "@/components/ProgramPlus.vue";
import CountactUs from "@/components/CountactUs.vue";
import { mapActions, mapGetters } from "vuex";
import {useHead} from "@vueuse/head"
import { computed, reactive } from "vue"
export default {
  name: "HomeView",
  components: {
    HeaderMain,
    FooterMain,
    MainComponent,
    History,
    Scholarship,
    ScholarshipPlus,
    Counter,
    Feedback,
    Program,
    ProgramPlus,
    CountactUs,
  },
  data() {
    return {}
  },
  setup() {
    const siteData = reactive({
        title: localStorage.getItem('meta_title') || 'Главная страница',
        description: localStorage.getItem('meta_description') || 'Главная страница',
        keywords: localStorage.getItem('meta_key') || 'Главная страница'
    })

    useHead({
        meta: [
            {
                name:  computed(() => siteData.title),
                content: computed(() => siteData.description && siteData.keywords),
            },
        ],
    })
  },
  async created() {
    await this.GET_MAIN_PAGE();
    localStorage.setItem('meta_title',this.MAIN_PAGE?.seoPage?.meta_title)
    localStorage.setItem('meta_description',this.MAIN_PAGE?.seoPage?.meta_description)
    localStorage.setItem('meta_key', this.MAIN_PAGE?.seoPage?.meta_keyword)
  },
  methods: {
    ...mapActions(["GET_MAIN_PAGE"]),
  },
  computed: {
    ...mapGetters(["MAIN_PAGE"]),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.scholarships {
  background-image: url("@/assets/images/mainPage/Scholarship-bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: linear-gradient rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}
.arrow-down {
    position: fixed;
    bottom: 20%;
    right: 3%;
    cursor: pointer;
}
.arrow-down span {
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #337AB7;
    border-right: 5px solid #337AB7;
    transform: rotate(225deg);
    margin: -16px;
    animation: arrow-down 2s infinite;
}

.programs {
  background-image: url("@/assets/images/mainPage/programs-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1024px) {
  .scholarships {
    background-position: inherit;
    background-image: url('@/assets/images/scholarship-back.png');
  }
}
</style>

<template>
    <div class="premium">
        <div class="premium__left">
            <platformTable />
        </div>
        <div class="premium__right">
            <div class="premium__right__top">
                <div class="premium__right__top__title">
                    Премиум опции
                </div>
                <div class="premium__right__top__right">
                   <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="premium__right__block">
                <div class="premium__right__block__in">
                    <p class="premium__right__block__in__title">Купите новые услуги</p>
                    <div class="premium__right__block__in__bot">
                        <div class="premium__right__block__in__bot__in" v-for="item in 5">
                            <grantCard />
                        </div>
                    </div>
                </div>
                    <div class="premium__right__block__in">
                        <p class="premium__right__block__in__title">Restock</p>
                        <div class="premium__right__block__in__bot">
                            <div class="premium__right__block__in__bot__in" v-for="item in 3">
                                <grantCard />
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import grantCard from '@/components/grantCard.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
export default {
    components:{
        platformTable, Swiper, SwiperSlide, newCardComponent,userInfo, grantCard
    },
    data(){
        return{
            activeTab: 'list',
            selectedStip: -1,
            selectedStep: -1,
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.world{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &__left, &__right{
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 25px;
        &__block{
            display: flex;
            gap: 4%;
            &__in, &__in1{
                width: 22%;
                cursor: pointer;
                display: flex;
                padding: 20px 10px;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                font-weight: 600;
                img{
                    height: 35px;
                }
            }
            &__in1{
                background-color: #FFFFFF;
                img{
                    height: 35px;
                }
            }
            .customStip{
                color: white;
                background: #FD2D2A;
                img{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(6096%) hue-rotate(182deg) brightness(117%) contrast(99%);
                }
                .line{
                    border-top: 1px solid white;
                }
            }
            &__in{
                color: #FD2D2A;
                background: white;
                img{
                    filter: brightness(0) saturate(100%) invert(64%) sepia(84%) saturate(7188%) hue-rotate(344deg) brightness(102%) contrast(105%);
                }
                .line{
                    border-top: 1px solid #FD2D2A;
                }
            }
        }
    }
}
.premium{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;
    &__left{
        width: 20%;
    }
    &__right{
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__title{
                font-size: 36px;
                font-weight: 600;
            }
            &__right{
                width: 25%;
            }
        }
        &__block{
            display: flex;
            flex-direction: column;
            gap: 30px;
            &__in{
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__title{
                    color: #0000008A;
                    font-size: 24px;
                }
                &__bot{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2.5%;
                    row-gap: 20px;
                    &__in{
                        width: 18%;
                    }
                }
            }
        }
    }
}
</style>
<template>
  <div
    class="backgroundSign block animated fadeIn"
    v-if="$store.state.openVideoModal"
    @click="$store.state.openVideoModal = false"
    v-scroll
  >
    <form class="sign" @click.stop @submit.prevent="signIn">
      <!-- 
      <button
        class="sign-registrBtn w24-700"
        @click="$store.state.openSendModal = false"
        type="submit"
      >
        Готово
      </button> -->
    </form>
  </div>
</template>
<script>
// import { mapActions } from "vuex";
// import scrollDirectives from "./Directives/scrollDirectives";
// import user from "@/data/user.json";

export default {
  data() {},
};
</script>
<style lang="scss" scoped>
.valid {
  color: red;
  text-align: start;
}
.block {
  display: none; /* блок по умолчанию скрыт */
}
.animated {
  animation-duration: 1s; /* длительность анимации */
  animation-fill-mode: both; /* сохраняем состояние после анимации */
}
.fadeIn {
  animation-name: fadeIn; /* имя анимации */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.backgroundSign {
  color: black;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign {
  width: 392px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 18px;
  // gap: 16px;
  //   @media (max-width: $tablet) {
  //     width: 95%;
  //     box-sizing: border-box;
  //   }

  &__mentor {
    position: relative;
    &-line {
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 356px;
      margin-top: 14px;
      width: 100%;
      position: relative;
    }
    &-img {
      display: flex;
      width: 157.5px;
      height: 157.5px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 42px;
    }
    &-name {
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-bottom: 14px;
      margin-top: 18px;
      font-weight: 400;
      font-size: 24px;
    }
  }

  &-registrBtn {
    height: 60px;
    text-align: center;
    background-color: #11415d;
    border-radius: 8px;
    padding: 16px 136px;
    width: 356px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 41px;
    display: flex;
    align-items: center;
    border: none;
  }
}
</style>

<template>
    <div class="addFileBack">
        <div class="addFile">
            <div class="addFile__top">
                <p class="addFile__top__title">Добавить файл</p>
                <p class="addFile__top__sub">Укажите данные товара и добавьте покупку пользователю</p>
            </div>  
            <div class="line"></div>
            <div class="addFile__select">
                <div class="customInput">
                    <span>Выберите категорию файла</span>
                    <select>
                        <option>Резюме</option>
                    </select>
                </div>
            </div>
            <div class="addFile__block">
                <div class="customInput">
                    <span>Текст основной</span>
                    <label>
                        <input type="file" style="display: none;"/>
                        <p>Название файла</p>
                        <img src="@/assets/icons/document.svg"/>
                    </label>
                </div>
                <p>*Иконка файла будет добавлена автоматически</p>
            </div>
            <div class="line"></div>
            <div class="addFile__buttons">
                <button class="addFile__buttons__save">Добавить</button>
                <button class="addFile__buttons__delete" @click="closeModal()">Закрыть</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
      'isRedactor', 'isAdd'  
    ],
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
    .addFileBack{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 999;
        align-items: center;
        background-color: #0000007a;
    }
    .addFile{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        width: 33%;
        background-color: white;
        padding: 40px 60px;
        border-radius: 25px;
        &__top{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            &__title{
                font-size: 32px;
                color: #11415D;
                font-weight: 600;
            }
            &__sub{
                color: #0000008A;
            }
        }
        &__select{
            width: 100%;
        }
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 4%;
            width: 100%;
            row-gap: 10px;
            P{
                color: #0000008A;   
            }
        }
    &__buttons{
        display: flex;
        width: 100%;
        gap: 4%;
        button{
            width: 48%;
            border: none;
            height: 50px;
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: 20px;
            background-color: #11415D;
            border: 1px solid #11415D;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2076a7;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: 20px;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }
    }
    
.customInput{
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer !important;
    span{
        position: absolute;
        top: -8px;
        left: 25px;
        background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: 14px;
        color: #c0c0c0; 
    }
    label, select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 25px;
        height: 50px;
        border: 1px solid #e0e0e0; /* Цвет рамки */
        border-radius: 8px;
        font-size: 20px;
        background-color: inherit;
        color: #000; /* Цвет текста */
        outline: none;
        img{
            width: 20px;
            height: 20px;
        }
    }
    }
</style>
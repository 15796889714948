<template>
  <div class="history" v-if="MAIN_PAGE">
    <div class="container">
      <div class="history__main">
        <div class="history__content" v-if="MAIN_PAGE?.homeCommand">
          <h3 class="history__content-title">{{ this.$t("about") }}</h3>
          <h1 class="history__content-title2" v-if="MAIN_PAGE?.homeCommand?.title" v-html="MAIN_PAGE.homeCommand.title">
          </h1>
          <div class="history__content-info">
            <p class="history__content-info-in" v-if="MAIN_PAGE?.homeCommand?.description"
              v-html="MAIN_PAGE.homeCommand.description"></p>
          </div>
        </div>
        <div class="history__cards d-flex" v-if="MAIN_PAGE.homeCommandItems">
          <swiper :slides-per-view="4" :breakpoints="{
            1800: {
              slidesPerView: 4,
            },
            1000: {
              slidesPerView: 4,
            },
            990: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1.4,
            },
          }" class="mySwiper">
            <swiper-slide class="history__card" v-for="(item, index) in MAIN_PAGE.homeCommandItems" :key="index">
              <router-link :to="{path: '/about', hash: `${item.link}`}">
                <div class="history__card-title flex-row-between-center">
                  <p class="history__card-title-name">{{
                    item.title
                  }}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 24">
                    <path fill="#7C7C7C"
                      d="M8.7 17.3q-.275-.275-.275-.7t.275-.7l3.9-3.9l-3.9-3.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.6 4.6q-.275.275-.7.275t-.7-.275Z" />
                  </svg>
                </div>
                <p class="history__card-text" v-html="item.description"></p>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>

        <div class="history__partners d-flex-center" v-if="MAIN_PAGE?.homePartnerItems">
          <swiper :navigation="navigation11" :slides-per-view="6" :autoplay="{
            duration: 1000,
          }" :breakpoints="{
            1800: {
              slidesPerView: 6,
            },
            1000: {
              slidesPerView: 5,
            },
            768: {
              slidesPerView: 4,
            },
            0: {
              slidesPerView: 3,
            },
          }" class="mySwiper">
            <swiper-slide class="history__partner" v-for="(item, index) in MAIN_PAGE.homePartnerItems" :key="index" >
                <img :src="item.image" alt="" class="history__partner-image"/>
            </swiper-slide>
          </swiper>
          <div class="nav">
            <div class="swiper-button-prev swiperPrev11">
              <img src="@/assets/images/mainPage/linia-left.png" alt="" />
            </div>
            <div class="swiper-button-next swiperNext11">
              <img src="@/assets/images/mainPage/linia-right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { Navigation, Autoplay } from "swiper";
// import VLazyImage from "v-lazy-image";
SwiperCore.use([Navigation, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["MAIN_PAGE"],
  data() {
    return {
      breakpoints: {
        1300: {
          slidesPerView: 4,
        },
        990: {
          slidesPerView: 4,
        },
        760: {
          slidesPerView: 2,
        },
        460: {
          slidesPerView: 0.6,
          sliderPerGroup: 0.6,
        },
        0: {
          slidesPerView: 1,
          sliderPerGroup: 1,
        },
      },
      navigation11: {
        nextEl: ".swiperNext11",
        prevEl: ".swiperPrev11",
      },
    };
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.my-swiper {
  width: 100%;
}

.container {
  position: relative;
}

.nav {
  position: absolute;
  width: 100%;

}

.swiper-button-next {
  right: -16px;

  &::after {
    opacity: 0;
  }

  img {
    width: 13px;
    height: 22px;
  }
}

.swiper-button-prev {
  left: -30px;

  &::after {
    opacity: 0;
  }

  img {
    width: 13px;
    height: 22px;
  }
}

.history {
  background-color: #f5f5f5;

  &__partner {
    &-image {
      // filter: grayscale(100%);
      // opacity: 0.6;
    }
  }

  &__content {
    &-title {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #fd2d2a;
      padding-top: 145px;
    }

    &-title2 {
      font-weight: 700;
      font-size: 36px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #000000;
      padding: 4px 0px 18px;
    }

    &-info {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55%;
      margin-left: auto;
      margin-right: auto;

      &-in {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #7c7c7c;
      }
    }
  }

  &__cards {
    width: 100%;
    margin-top: 80px;

    @media screen and (max-width: $tablet) {
      margin-top: 30px;
      width: 100%;
    }
  }

  &__card {
    width: 390px;
    height: 237px;
    border-radius: 8px;
    // margin-right: 24px;
    padding: 18px;

    @media screen and (max-width: $mobile) {
      width: 10%;
      height: 250px;
      padding: 0px;
    }

    &:hover {
      background-color: rgba(17, 65, 93, 0.05);
    }

    &:hover>.history__card-title {
      color: #000000;
    }

    &:hover>.history__card-title .history__card-title-name {
      color: #000000;
    }

    &:hover>.history__card-text {
      color: #000000;
    }

    &:active {
      background-color: #fd2d2a;
      p {
        color: #ffffff !important;
      }
    }

    &:active>.history__card-title {
      color: #ffffff;
    }

    &:active>.history__card-title .history__card-title-name {
      color: #ffffff;
    }

    &:active>.history__card-text {
      color: #ffffff;
    }

    &-title {
      padding-bottom: 28px;

      &-name {
        font-weight: 700;
        font-size: 24px;
        color: #7c7c7c;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #7c7c7c;
      -webkit-line-clamp: 6;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__partners {
    width: 100%;
    padding: 112px 0px 116px;
    .swiper-wrapper {
      align-items: baseline !important;
    }
  }

  &__partner {
    margin-right: 0px;
    &-slider {
      height: 200px;
    }
    img {
      transition: 0.4s;
      // filter: grayscale(1);
      width: 90%;
      &:hover {
        transition: 0.4s;
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .history {
    &__content {
      &-info {
        width: 75%;
      }
    }

    &__card {
      width: 390px;
      height: 237px;

      &-title {
        padding-bottom: 10px;

        &-name {
          font-size: 24px;
        }
      }

      &-text {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 990px) {
  .nav {
    display: none;
    // position: absolute;
    // width: 94%;
    // height: 20px;
    // display: flex;
    // justify-content: space-between;
  }

  .swiper-button-prev {
    left: 0px;
  }

  .history {
    &__content {
      text-align: left;

      &-title {
        text-align: left;
      }

      &-title2 {
        text-align: left;
      }

      &-info {
        width: 100%;

        &-in {
          text-align: left;
        }
      }
    }

    &__card {
      width: 290px;
      height: 237px;
      border-radius: 8px;
      margin-right: 5px;
      padding: 8px !important;
      border: 1px solid rgba(0, 0, 0, 0.10);
      @media (max-width: $mobile) {
        padding: 0px;
      }
    }

    &__partners {
      width: 100%;
      padding: 20px 0px 23px;
    }

    &__partner {
      margin-right: 0 !important;
      & img {
        width: 90%;
        height: 80px;
        object-fit: contain;
        // filter: grayscale(100%);
      }

      margin-right: 24px;
    }
  }
}

@media (max-width: 576px) {
  .history {
    &__cards {
      .swiper-slide {
        // filter: blur(2px);

        &-active {
          filter: unset !important;
        }
      }
    }
  }
}</style>

<template>
  <div class="mainComponent" v-if="MAIN_PAGE">
    <swiper
      :spaceBetween="30"
      :centeredSlides="true"
      :autoplay="{
        delay: 5000,
      }"
      :pagination="{
        type: 'fraction',
      }"
      :modules="modules"
      :navigation="navigation1"
      :loop="true"
      class="mySwiper m_none"
      v-if="MAIN_PAGE.banners"
    >
      <swiper-slide v-for="(item, index) in MAIN_PAGE.banners" :key="index">
        <div class="container">
          <div class="mainComponent__content d-flex">
            <div class="mainComponent__content-left">
              <div class="mainComponent__content-left__wrap">
                <a :href="item.file">
                  <button class="mainComponent__content-btn pulse">{{ this.$t("newG") }}</button>
                </a>
                <h1 class="mainComponent__content-title w48-700">
                  {{ item.title }}
                </h1>
                <p
                  class="mainComponent__content-info"
                  v-html="item.description"
                ></p>
              </div>
              <a @click="buttonClick(item.link)" class="mainComponent__content-buttons desktop-btn m_none">
                <button class="mainComponent__content-buttons-buy" v-html="item.btn_name"></button>
              </a>
            </div>
            <div class="mainComponent__content-right">
              <div class="videoBlocks">
                <div class="video__item">
                  <div class="videoplay">
                    <img
                      class="videoplay__img"
                      @click="videoClicked(index)"
                      src="@/assets/images/aboutPage/playVideo.svg"
                      alt=""
                    />
                  </div>
                  <iframe
                    :class="{'iframe-animation' : isClicked}"
                    width="909"
                    height="527"
                    :src="item.video"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="nav m_none">
        <div class="swiper-button-prev swiperPrev">
          <img src="@/assets/images/mainPage/wh-left.png" alt="" />
        </div>
        <!-- <p>123</p> -->
        <div class="swiper-button-next swiperNext">
          <img src="@/assets/images/mainPage/wh-right.png" alt="" />
        </div>
      </div>
    </swiper>
    <swiper
      :spaceBetween="30"
      :centeredSlides="true"
      :pagination="pagination"
      :modules="modules"
      class="mySwiper d_none"
      v-if="MAIN_PAGE.banners"
    >
      <swiper-slide v-for="(item, index) in MAIN_PAGE.banners" :key="index">
        <div class="container">
          <div class="mainComponent__content d-flex">
            <div class="mainComponent__content-left">
              <a :href="item.file">
                <button class="mainComponent__content-btn pulse">{{ this.$t("newG") }}</button>
              </a>
              <h1 class="mainComponent__content-title w48-700">
                {{ item.title }}
              </h1>
              <p
                class="mainComponent__content-info"
                v-html="item.description"
              ></p>
            </div>
            <div class="mainComponent__content-buttons d-flex-center d_none">
              <a class="" @click="buttonClick(item.link)">
                <button class="mainComponent__content-buttons-buy" v-html="item.btn_name"></button>
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination">
        <span v-for="(item, index) in MAIN_PAGE.banners" :key="index">{{ item.title }}</span>
      </div> -->
    </swiper>
    <div class="line red d_none"></div>
    <div class="line yellow d_none"></div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isClicked: false,
      navigation1 : {
        nextEl: ".swiperNext",
        prevEl: ".swiperPrev",
      },
    }
  },
  props: ["MAIN_PAGE"],
  setup() {
    if(localStorage.getItem("lang") == "en") {
      var slideNames = ["Promo","Anncmnt","News","Discount"];
    } else if(localStorage.getItem("lang") == "kz") {
      var slideNames = ["Науқан","Анонс","Жаңалық","Жеңілдік"];
    } else {
      var slideNames = ['Акция','Анонс ','Новости','Скидки'];
    }
    return {
      modules: [Autoplay, Pagination, Navigation],
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class="${className}">${slideNames[index]}</span>`;
          // return '<span class="' + className + '">' + 'Слайд ' + (index + 1) + '</span>';
        },
      },
    };
  },
  methods: {
    buttonClick(link) {
      window.location.href = link;
    },
    videoClicked() {
      this.isClicked = true;
      document.querySelector(".videoplay").classList.add("videoplay-animation");
      this.autoplay = "?autoplay=1";
      setTimeout(() => {
        document.querySelector("iframe").classList.add("iframe-animation");
      }, 200);
      console.log("asd123!!");
    },
    // getUrl(value) {
    //   const regex = /src="([^"]+)"/;
    //   const match = value.match(regex);

    //   if (match && match[1]) {
    //     const srcValue = match[1];
    //     return srcValue;
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.pulse {
  animation: pulse-animation 2s infinite;
}
.pulse-red {
  animation: pulse-red-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(236, 226, 226, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(236, 226, 226, 0);
  }
}
@keyframes pulse-red-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(245, 2, 2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(245, 2, 2, 0);
  }
}
.swiper-pagination-progressbar  {
  position: absolute;
  top: 95%;
  background: transparent;
}
.nav {
  position: absolute;
  bottom: 18%;
  width: 10%;
  left: 45.5%;
  .swiper-button {
    &-prev, &-next {
      img {
        height: 30px;
      }
      &::after {
        opacity: 0;
      }
    }
  }
}
// .desktop {
//   &-btn {
//     margin-top: 30%;
//   }
// }
.mobile {
  display: none;
  @media screen and (max-width: $tablet) {
    display: flex;
    gap: 10px;
    margin-left: 30px;
    align-items: center;
    img {
      width: 23px;
      height: 23px;
    }
    p {
      color: rgba(255, 255, 255, 0.54);
      font-size: 16px;
    }
  }
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  border-radius: 20px;
}
.iframe-animation {
  z-index: 2;
  opacity: 1;
  transition: opacity 2s;
}

.videoBlocks {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  width: 909px;
  // height: 527px;
}

.video {
  &__item {
    position: relative;
    background-image: url(@/assets/images/mainPage/main-sw-bg.png);
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 515px;
  }
}
.videoplay {
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44px;
  text-align: center;
  align-items: center;
  &__img {
    width: 100px !important;
    height: 100px !important;
  }
  // &__img {
  //   width: 100px;
  //   height: 100px;
  //   position: absolute;
  //   top: 50%;
  //   right: 50%;
  // }
}
.line {
  height: 8px;
  width: 100%;
}
.red {
  background-color: #fd2d2a;
  margin-bottom: 8px;
}
.yellow {
  background-color: #FFE19C;
  padding-bottom: 8px;
}
.mainComponent {
  background-image: url("@/assets/images/mainPage/main-banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .swiper-slide {
    padding-bottom: 25px;
  }
  &__content {
    padding-bottom: 128px;
    &-left {
      width: 70%;
      margin-right: 60px;
      padding-top: 135px;
    }
    &-btn {
      font-weight: 600;
      font-size: 16px;
      color: #11415d;
      background: #ffffff;
      border: 1px solid #11415d;
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 12px;
      transition: 0.4s;
      // &:hover {
      //   background-color: #fd2d2a;
      //   color: #fff;
      //   transition: 0.4s;
      // }
    }
    &-title {
      line-height: 59px;
    }
    &-info {
      font-weight: 600;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.54);
      line-height: 22px;
    }
    &-buttons {
      padding-top: 0px;
      padding-bottom: 150px;
      @media screen and (max-width: $tablet) {
        padding-top: 42px;
        padding-bottom: 100px;
      }
      &-buy {
        background: #fd2d2a;
        border-radius: 8px;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        padding: 16px 80px;
        border: none;
        transition: 0.4s;
        margin-top: 0;
        &:hover {
          transition: 0.4s;
          background-color: #fff;
          color: #fd2d2a;
        }
      }
      &-more {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.54);
        margin-left: 60px;
        transition: 0.4s;
        border-radius: 12px;
        &:hover {
          transition: 0.4s;
          color: rgb(255, 255, 255);
        }
        @media screen and (max-width: $tablet) {
          display: none;
        }
      }
    }
    &-right {
      max-width: 909px;
      max-height: 527px;
      padding-top: 135px;
      // margin-top: 0px;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .video {
    &__item {
      height: 400px;
    }
  }
  .videoBlocks {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 609px;
  }
  .mainComponent {
    // background-position: unset;
    &__content {
      &-left {
        width: 70%;
        margin-right: 60px;
        padding-top: 135px;
        height: 530px !important;
      }
      &-btn {
        font-size: 16px;
        padding: 12px;
        margin-bottom: 12px;
      }
      &-title {
        font-size: 38px;
        line-height: 44px;
      }
      &-info {
        font-size: 14px;
      }
      &-buttons {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 150px;
        &-buy {
          font-size: 22px;
          padding: 16px 70px;
        }
      }
      &-right {
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media (min-width: 990px) {
  .mainComponent {
    &__content {
      padding-bottom: 270px;
      &-title {
        margin-bottom: 24px;
      }
      &-left {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 650px;
      }
      &-buttons {
        padding-bottom: 0;
        &-buy {
          padding: 15px 0;
          max-width: 440px;
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  .video {
    &__item {
      height: 350px;
    }
  }
  .videoBlocks {
    width: 509px;
  }
}
@media (max-width: 990px) {
  .mainComponent {
    width: 100%;
    padding-bottom: 8px;
    &__content {
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 100px !important;
      min-height: 600px;
      &-left {
        width: 100%;
      }
      &-info {
        padding-bottom: 15px;
      }
      &-title {
        width: 100%;
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
      }
      &-btn {
        padding: 6px;
      }
      &-right {
        display: none;
      }

      &-buttons {
        padding: 0;
        &-buy {
          font-size: 16px;
          padding: 9px 36px;
          border-radius: 4px;
        }
      }
    }
  }
}
@media (max-width: 560px) {
  .mainComponent {
    background-image: url('@/assets/images/main-banner.png');
    &__content {
      padding-bottom: 0px;
      &-left {
        margin-right: 0;
        padding-top: 100px;
      }
      &-title {
        margin-bottom: 24px;
      }
      &-buttons {
        align-items: baseline;
      }
    }
  }
}
</style>

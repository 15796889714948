<template>
  <div class="load-row Loader" v-show="getLoading">
    <!-- <span></span>
      <span></span>
      <span></span>
      <span></span> -->
    <img src="@/assets/images/animation1.gif" alt="" />
  </div>
  <div class="done" v-show="!getLoading">
    <router-view />
  </div>
  <div class="link1" v-show="!getLoading" v-if="$route.meta.isPlatform == false">
    <div class="arrow-down" @click="scrollToTop">
      <img src="@/assets/images/arrow-red.png" alt="" />
    </div>
    <hr />
    <div class="whats" v-if="CONTACT_PAGE">
      <a :href="CONTACT_PAGE?.contact?.whatsapp_link">
        <img src="@/assets/images/WhatsApp.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    this.GET_CONTACT_PAGE();
  },
  methods: {
    ...mapActions(["GET_CONTACT_PAGE"]),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    ...mapGetters(["CONTACT_PAGE", "getLoading"]),
  },
};
</script>

<style lang="scss">
.done {
  // min-height: 100vh;
  // min-width: 100vw;
  // display: flex;
  // flex-direction: column;
  // align-items: stretch;
  // &-router {
  //   flex-grow: 1;
  // }
}
.Loader {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfafc;
}
.load-row {
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  img {
    object-fit: contain;
    width: 140px;
  }
}
.link1 {
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  position: fixed;
  bottom: 2%;
  right: 2%;
  display: flex;
  flex-direction: column;
  padding: 5px 14px 15px 14px;
  z-index: 1110;
  hr {
    margin: 16px 0;
  }
}
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(78, 148, 105, 0.297);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(178, 243, 199, 0.392);
  }
}
#app {
  overflow-x: hidden;
}
.whats {
  border-radius: 50%;
  cursor: pointer;
  z-index: 111;
  img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      transform: scale(1.2);
      transition: 0.4s;
    }
  }
}
.arrow-down {
  cursor: pointer;
}
.arrow-down span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #fd2d2a;
  border-right: 5px solid #fd2d2a;
  transform: rotate(225deg);
  margin: -16px;
  // animation: arrow-down 2s infinite;
}
.arrow-down span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow-down span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes arrow-down {
  0% {
    opacity: 0;
    transform: rotate(225deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(225deg) translate(20px, 20px);
  }
}
@media (max-width: 1024px) {
  .arrow-down {
    right: 10%;
    z-index: 111;
    img {
      width: 28px;
      height: 28px;
      margin: 0 auto;
      display: flex;
    }
  }
  .link1 {
    border-radius: 12px;
    padding: 10px;
  }
  .whats {
    right: 4%;
    z-index: 111;
    bottom: 7%;
    img {
      width: 44px;
      height: 44px;
    }
  }
}
</style>

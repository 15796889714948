<template>
    <div class="grantCard">
        <div class="grantCard__img">
            <div class="bilim">
                <img src="@/assets/icons/bilim.svg"/>
                <p>CHOICE</p>
            </div>
        </div>
        <p class="grantCard__category">Брошюра</p>
        <button>Подробнее</button>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
    .grantCard{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 10px;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        &__img{
            width: 100%;
            aspect-ratio: 1;
            position: relative;
            background-image: url('/src/assets/icons/test.png');
            background-size: cover;
            background-position: center;
            border-radius: 10px;
                .bilim{
                    position: absolute;
                    z-index: 998;
                    background-color: white;
                    top: 10px;
                    left: 10px;
                    display: flex;
                    gap: 5px;
                    color: white;
                    img{
                        width: 13px;
                    }
                    font-size: 12px;
                    padding: 5px;
                    border-radius: 5px;
                    background-color: #FD2D2A;
                    align-items: center;
                }
        }
        &__stip{
            color: #0000008A;
        }
        &__category{
            font-weight: 700;
        }
        button{
            width: 100%;
            border-radius: 10px;
            padding: 5px 0px;
            background-color: #FD2D2A;
            border: none;
            color: white;
            font-weight: 700;
        }
    }
</style>
<template>
    <EditCurator v-if="openEdit" @closeModal="openEdit = false"/>
    <div class="curator">
        <div class="curator__left">
            <platformTable />
        </div>
        <div class="curator__right">
            <div class="curator__right__top">
                <div class="curator__right__top__left">
                    Главная страница
                </div>
                <div class="curator__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="curator__right__first">
                <div class="curator__right__first__left">
                    <div class="info customBackground">
                        <div class="info__top" style="cursor: pointer;">
                            <img src="@/assets/icons/curator.svg" class="info__top__image" @click="openEdit = true"/>
                            <div class="info__top__right" @click="openEdit = true">
                                <p class="info__top__right__name">Джеймс Д.</p>
                                <div class="info__top__right__status">
                                    <img src="@/assets/icons/curatorIcon.svg"/>
                                    <p>Куратор</p>
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="info__status">
                            Статус: <span>Активен</span>
                        </div>
                    </div>
                    <div class="apps customBackground">
                        <div class="apps__top">
                            <div class="apps__top__left">
                                <img src="@/assets/icons/app.svg"/>
                                <p>Заявки на обсуждение</p>
                            </div>
                        </div>
                        <div class="apps__bot">
                            <swiper 
                            class="apps__bot__swiper"
                                :breakpoints="{
                                    0: {
                                        slidesPerView: 3
                                    }
                                }"
                                :pagination="{
                                     el: '.swiper-pag',
                                }"
                                :spaceBetween="40"
                                :speed="500"
                                :lazy="true" 
                            > 
                                <swiper-slide v-for="(item, index) in 4" class="apps__bot__swiper__slide"
                                    :key="item"> 
                                    <img src="@/assets/icons/appUser.svg"/>
                                    <p class="test">ID:897688</p>
                                    <p class="name">Иван И.</p>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiper-pag"></div>
                        </div>
                    </div>
                </div>
                <div class="curator__right__first__center">
                    <div class="achieve customBackground">
                        <div class="achieve__top">
                            <div class="achieve__top__left">
                                <img src="@/assets/icons/achieveIcon.svg"/>
                                <p>Результаты</p>
                            </div>
                            <button>Сохранить</button>
                        </div>
                        <div class="achieve__block">
                            <div class="achieve__block__in" v-for="item in 4">
                                <div class="achieve__block__in__top">
                                    <div class="achieve__block__in__top__circle text">
                                        78
                                    </div>
                                    <p>Офферы<br/>ТОП 30</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="curator__right__first__last">
                    <div class="count customBackground">
                        <div class="count__top">    
                            <img src="@/assets/icons/achieveIcon.svg"/>
                            <p>Клиенты</p>
                        </div>
                        <div class="count__bot">
                            <div class="count__bot__in" v-for="item in 9" :key="item">
                                <p class="name">32</p>
                                <p class="text">Кол.</p>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="curator__right__second">
                <div class="curator__right__second__left">
                    <div class="clients customBackground">
                        <div class="clients__top">
                            <div class="clients__top__left">
                                <img src="@/assets/icons/clients.svg"/>
                                <p>Клиенты дня</p>
                            </div>
                        </div>
                        <div class="clients__bot">
                            <swiper 
                            class="clients__bot__swiper"
                                :breakpoints="{
                                    0: {
                                        slidesPerView: 6
                                    }
                                }"
                                :pagination="{
                                     el: '.swiper-pag1',
                                }"
                                :spaceBetween="40"
                                :speed="500"
                                :lazy="true" 
                            > 
                                <swiper-slide v-for="(item, index) in 7" class="clients__bot__swiper__slide"
                                    :key="item"> 
                                    <img src="@/assets/icons/appUser.svg"/>
                                    <p class="test">ID:897688</p>
                                    <p class="name">Иван И.</p>
                                </swiper-slide> 
                            </swiper>
                            <div class="swiper-pag1"></div>
                        </div>
                    </div>
                </div>  
                <div class="curator__right__second__last">
                    <div class="access customBackground">
                        <div class="access__top">
                            <img src="@/assets/icons/access.svg"/>
                            <p>Доступ к ресурсам</p>
                        </div>
                        <div class="access__bot">
                            <div class="access__bot__in" v-for="item in 8" :key="item">
                                <img src="@/assets/icons/tg.svg" /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from "@/components/platformTable.vue";
import userInfo from "@/components/userInfo.vue";
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useHead } from "@vueuse/head"
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import "swiper/swiper.min.css";
import EditCurator from "@/components/newPlatform/editCurator.vue";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectCoverflow])
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        EditCurator
    },
    data() {
        return {
            openEdit: false
        };
    },
};
</script>
<style lang="scss" scoped>
.text{
    color: #0000008A;
}
.name{
    color: black;
    font-weight: 700;
    font-size: 18px;
}
.customBackground{
    background-color: #FFFFFF;
    box-shadow: 0px 4px 3px 1px #0000000D;
    border-radius: 12px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;
}
select{
    border: 1px solid #0000001A;
    padding: 5px;
    border-radius: 10px;
}
.curator {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #210C0C 0%, #C42D2B 53.75%);
    padding-top: 25px;
    min-height: 100vh;
    &__left {
        width: 20%;
    }
    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px; 
        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left {
                display: flex;
                gap: 30px;
                align-items: center;
                font-size: 36px;
                font-weight: 600;
            }
            &__right {
                width: 25%;
            }
        }
        &__first{
            display: flex;
            align-items: stretch;
            gap: 3.5%;
            &__left,&__center,&__last{
                width: 31%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .achieve{
                gap: 25px;
                &__top{
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &__left{
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        img{
                            width: 40px;
                        }
                    }
                    button{
                        background: inherit;
                        border: 1px solid #0000001A;
                        border-radius: 8px;
                    }
                }
                &__block{
                    padding: 25px 50px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 30%;
                    row-gap: 50px;
                    &__in{
                        width: 35%;
                        &__top{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            gap: 15px;
                            &__circle{
                                width: 100%;
                                aspect-ratio: 1;
                                border-radius: 50%;
                                border: 16px solid #FD2D2A;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
            .info{
                width: 100%;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 25px;
                height: fit-content;
                &__top{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    &__image{
                        width: 25%;
                        aspect-ratio: 1;
                    }
                    &__right{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        img{
                            width: 15px;
                        }
                        &__name{
                            color: #11415D;
                            font-weight: 700;
                            font-size: 32px;
                        }
                        &__status{
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            color: #FD2D2A;
                            font-weight: 600;
                        }
                    }
                }
                &__status{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 10px 0;
                    background-color: #14D448;
                    border-radius: 25px;
                    color: white;
                    font-size: 24px;
                    span{
                        font-weight: 600;
                
                    }
                }
            }
            .apps{
                height: fit-content;
                &__top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    &__left{
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        img{
                            width: 40px;
                        }
                    }
                }
                &__bot{
                    padding: 25px;
                    width: 100%;
                    &__swiper{
                        width: 100%;
                        &__slide{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 5px;
                            img{
                                width: 100%;
                                aspect-ratio: 1;
                            }
                        }
                    }
                }
            }
            .count{
                gap: 25px;
                &__top{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    padding: 10px;
                    img{
                        width: 40px;
                    }
                }
                &__bot{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5%;
                    row-gap: 20px;
                    padding: 25px 25px 80px;
                    &__in{
                        width: 30%;
                        aspect-ratio: 1;
                        box-shadow: 0px 0px 4px 4px #0000000D inset;
                        background-color: #F8F8F8;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 25px;
                    }
                }
            }
        }
        &__second{
            display: flex;
            justify-content: space-between;
            &__left{
                width: 65.5%;
                .clients{
                    &__top{
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        &__left{
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            img{
                                width: 40px;
                            }
                        }
                    }
                    &__bot{
                        padding: 10px;
                        width: 100%;
                        &__swiper{
                            width: 100%;
                            &__slide{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 5px;
                                img{
                                    width: 100%;
                                    aspect-ratio: 1;
                                }
                            }
                        }
                    }
                }
            }
            &__last{
                width: 31%;
                .access{
                    &__top{
                        padding: 10px;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        img{
                            width: 40px;
                        }
                    }
                    &__bot{
                        padding: 25px;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4%;
                        row-gap: 15px;
                        &__in{
                            width: 22%;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>
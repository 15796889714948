<template>
    <AboutProgram v-if="openModal" @closeModal="openModal = false"/>
    <div class="schools">
        <div class="schools__left">
            <platformTable />
        </div>
        <div class="schools__right">
            <div class="schools__right__top">
                <div class="schools__right__top__tabs">
                    <div class="schools__right__top__tabs__in" v-for="(tab, index) in tabs" :key="tab"
                        :class="{ customTab: selectedTab == index }" @click="selectedTab = index">
                        {{ tab.name }}
                    </div>
                </div>
                <div class="schools__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="schools__right__block" v-if="selectedTab == 0">
                <div class="schools__right__block__sale saleCard" v-for="item in sales" :key="item">
                    <div class="schools__right__block__sale__top">
                        <p class="schools__right__block__sale__top__title">{{ item.name }}</p>
                        <p class="schools__right__block__sale__top__text">{{ item.sub }}</p>
                    </div>
                    <a class="redButton" href="https://www.whatsapp.com/?lang=ru_RU" target="_blank">{{ item.buttonText }}</a>
                </div>
                <div class="indiv customBlock">
                    <div class="customTitle">
                        <p>Индивидуальные курсы general english</p>
                        <div class="line"></div>
                    </div>
                    <div class="indiv__block">
                        <div class="indiv__block__in" v-for="item in individual" :key="item">
                            <div class="indiv__block__in__left">
                                <p class="indiv__block__in__left__name">{{ item.name }}</p>
                                <p class="indiv__block__in__left__time">{{ item.time }}</p>
                            </div>
                            <button @click="openModal = true">Купить</button>
                        </div>
                    </div>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>Предложение</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft1" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight1',
                            prevEl: '.arrowLeft1'
                        }"
                        :breakpoints="{
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in 6" :key="item" class="offer__block__swiper__slide">
                                <img src="@/assets/icons/test.png"/>
                                <a href="https://idriesshahfoundation.org/books-childrens-books/?gad_source=1&gclid=CjwKCAiA3ZC6BhBaEiwAeqfvyv-qnSlVJ3zOfz9tSDb_WQp2uUKHP6iWPkEGPbDji26mVRgVA_4cLRoCIDEQAvD_BwE" target="_blank"><p class="offer__block__swiper__slide__name">Пробный урок</p></a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg" class="arrowRight1"/>
                    </div>
                </div>
                <div class="online customBlock">
                    <div class="customTitle">
                        <p>ОНЛАЙН КУРСЫ</p>
                        <div class="line"></div>
                    </div>
                    <div class="online__block">
                        <div class="online__block__in" v-for="item in online" :key="item">
                            <div class="online__block__in__box1">
                                <div class="online__block__in__box1__color"></div>
                            </div>
                            <div class="online__block__in__name">{{ item.name }}</div>
                            <div class="greenLine"></div>
                            <button>Доступен</button>
                        </div>
                    </div>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>Книга и гайды</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft2" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight2',
                            prevEl: '.arrowLeft2'
                        }"
                        :breakpoints="{
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in 6" :key="item" class="offer__block__swiper__slide">
                                <img src="@/assets/icons/test.png"/>
                                <a href="https://idriesshahfoundation.org/books-childrens-books/?gad_source=1&gclid=CjwKCAiA3ZC6BhBaEiwAeqfvyv-qnSlVJ3zOfz9tSDb_WQp2uUKHP6iWPkEGPbDji26mVRgVA_4cLRoCIDEQAvD_BwE" target="_blank" class="offer__block__swiper__slide__name">Книга</a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg" class="arrowRight2"/>
                    </div>
                </div>
                <div class="indiv customBlock">
                    <div class="customTitle">
                        <p>Групповые курсы general english</p>
                        <div class="line"></div>
                    </div>
                    <div class="indiv__block">
                        <div class="indiv__block__in" v-for="item in individual" :key="item">
                            <div class="indiv__block__in__left">
                                <p class="indiv__block__in__left__name">{{ item.name }}</p>
                                <p class="indiv__block__in__left__time">{{ item.time }}</p>
                            </div>
                            <button @click="openModal = true">Купить</button>
                        </div>
                    </div>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>НАШИ УЧИТЕЛЯ</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft3" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight3',
                            prevEl: '.arrowLeft3'
                        }"
                        :breakpoints="{
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in 6" :key="item" class="offer__block__swiper__slide">
                                <img src="@/assets/icons/test.png"/>
                                <a href="https://idriesshahfoundation.org/books-childrens-books/?gad_source=1&gclid=CjwKCAiA3ZC6BhBaEiwAeqfvyv-qnSlVJ3zOfz9tSDb_WQp2uUKHP6iWPkEGPbDji26mVRgVA_4cLRoCIDEQAvD_BwE" target="_blank"><p class="offer__block__swiper__slide__name">Фамилия Имя</p></a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg"  class="arrowRight3"/>
                    </div>
                </div>
                <div class="contact customBlock">
                    <div class="customTitle">
                        <p>КОНТАКТЫ И ПОМОЩЬ</p>
                        <div class="line"></div>
                    </div>
                    <div class="contact__block">
                        <div class="contact__block__in">
                            <img src="@/assets/icons/call.svg"/>
                            <p>Звонок в школу</p>
                        </div>
                        <div class="contact__block__in">
                            <img src="@/assets/icons/waa.svg"/>
                            <p>Групповой чат подготовки</p>
                        </div>
                        <div class="contact__block__in">
                            <img src="@/assets/icons/inst.svg"/>
                            <p>Инстаграм страница</p>
                        </div>
                        <div class="contact__block__in">
                            <img src="@/assets/icons/call.svg"/>
                            <p>Отзывы клиентов</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="schools__right__block" v-if="selectedTab == 1">
                <div class="cert saleCard">
                    <p class="cert__title">
                        Партнеры British Council
                    </p>
                    <button class="redButton">Показать сертификат</button>
                </div>
                <div class="test customBlock">
                    <div class="customTitle">
                        <p>ТЕСТЫ</p>
                        <div class="line"></div>
                    </div>
                    <div class="test__block">
                        <div class="test__block__in" v-for="item in 4" :key="item">
                            <img src="@/assets/icons/test.svg"/>
                            <a href="https://idriesshahfoundation.org/books-childrens-books/?gad_source=1&gclid=CjwKCAiA3ZC6BhBaEiwAeqfvyv-qnSlVJ3zOfz9tSDb_WQp2uUKHP6iWPkEGPbDji26mVRgVA_4cLRoCIDEQAvD_BwE" target="_blank"><p class="test__block__in__name">Placement Test</p></a>
                        </div>
                    </div>
                </div>
                <div class="online customBlock">
                    <div class="customTitle">
                        <p>ОНЛАЙН КУРСЫ</p>
                        <div class="line"></div>
                    </div>
                    <div class="online__block">
                        <div class="online__block__in" v-for="item in online" :key="item">
                            <div class="online__block__in__box1">
                                <div class="online__block__in__box1__color" :class="{customColor: item.status == 'no'}"></div>
                            </div>
                            <div class="online__block__in__name" :class="{customText: item.status == 'no'}">{{ item.name }}</div>
                            <div class="greenLine" :class="{customLine: item.status == 'no'}"></div>
                            <button v-if="item.status == 'yes'">Доступен</button>
                            <button v-else class="customButton">Не доступен</button>
                        </div>
                    </div>
                </div>
                <div class="offer customBlock">
                    <div class="customTitle">
                        <p>Книга и гайды</p>
                        <div class="line"></div>
                    </div>
                    <div class="offer__block">
                        <img src="@/assets/icons/selectRed.svg" class="arrowLeft4" style="rotate: 180deg;"/>
                        <swiper 
                        class="offer__block__swiper"
                        :navigation="{
                            nextEl: '.arrowRight4',
                            prevEl: '.arrowLeft4'
                        }"
                        :breakpoints="{
                            640: { slidesPerView: 4, spaceBetween: 30 },
                        }">
                            <swiper-slide v-for="item in 6" :key="item" class="offer__block__swiper__slide">
                                <img src="@/assets/icons/test.png"/>
                                <a href="https://idriesshahfoundation.org/books-childrens-books/?gad_source=1&gclid=CjwKCAiA3ZC6BhBaEiwAeqfvyv-qnSlVJ3zOfz9tSDb_WQp2uUKHP6iWPkEGPbDji26mVRgVA_4cLRoCIDEQAvD_BwE" target="_blank" class="offer__block__swiper__slide__name">Книга</a>
                            </swiper-slide>
                        </swiper>
                        <img src="@/assets/icons/selectRed.svg" class="arrowRight4"/>
                    </div>
                </div>
                <div class="indiv customBlock">
                    <div class="customTitle">
                        <p>ПОДГОТОВКА К ТЕСТАМ</p>
                        <div class="line"></div>
                    </div>
                    <div class="indiv__block">
                        <div class="indiv__block__in" v-for="item in individual" :key="item">
                            <div class="indiv__block__in__left">
                                <p class="indiv__block__in__left__name">{{ item.name }}</p>
                                <p class="indiv__block__in__left__time">{{ item.time }}</p>
                            </div>
                            <button @click="openModal = true">Купить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import userInfo from '@/components/userInfo.vue';
import { Navigation } from "swiper";
import AboutProgram from '@/components/newPlatform/aboutProgram.vue';
export default {
    components: {
        Swiper,
        SwiperSlide,
        platformTable,
        userInfo,
        AboutProgram
    },
    data() {
        return {
            selectedTab: 0,
            openModal: false,
            online: [
                {
                    name:'ELEMENTARY',
                    status: 'yes'
                },
                {
                    name:'PRE-INTERMEDIATE',
                    status: 'yes'
                },
                {
                    name:'PRE-INTERMEDIATE',
                    status: 'yes'
                },
                {
                    name:'UPPER-INTERMEDIATE',
                    status: 'yes'
                },
            ],
            sales: [
                {
                    name: 'Запишись на бесплатный пробный урок',
                    sub: 'Дополнительный текст',
                    buttonText: 'Записаться'
                },
                {
                    name: 'Online SPEAKING CLUB',
                    sub: 'Дополнительный текст',
                    buttonText: 'Перейти к записи'
                },
            ],
            individual: [
                {
                    name: 'ELEMENTARY',
                    time: '3 месяца'
                },
                {
                    name: 'PRE-INTERMEDIATE',
                    time: '2 месяца'
                },
                {
                    name: 'INTERMEDIATE',
                    time: '3 месяца'
                },
                {
                    name: 'UPPER-INTERMEDIATE',
                    time: '2 месяца'
                },
                {
                    name: 'ADVANCED',
                    time: '2 месяца'
                },
            ],
            tabs: [
                {
                    name: 'Языковая школа'
                },
                {
                    name: 'Тестовая школа'
                },
            ],
        }
    }
}
</script>
<style lang="scss" scoped>
.customButton{
    border: 1px solid  #000000 !important;
    color: #000000 !important;
}
.customColor{
    background-color: inherit !important;
}
.customText{
    color: #000000 !important;
}
.customLine{
    border-top: 1px solid  #000000 !important;
}
.customBlock{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 30px;
    background-color: white;
    width: 49%;
    border-radius: 10px;
}
.indiv {
    &__block {
        display: flex;
        flex-direction: column;
        gap: 15px;
        &__in {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__left {
                display: flex;
                align-items: center;
                gap: 5px;

                &__name {
                    font-weight: 700;
                }
            }
            button {
                width: 20%;
                border: 1px solid #FD2D2A;
                background-color: inherit;
                color: #FD2D2A;
                border-radius: 5px;
                font-weight: 700;
            }
        }
    }
}
.online{
    &__block{
        display: flex;
        flex-direction: column;
        gap: 20px;
        &__in{
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: space-between;
            &__box1{
                width: 5% !important;
                aspect-ratio: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #0000001A;
                &__color{
                    width: 20px;
                    height: 20px;
                    background-color: #00D928;
                }
            }
            &__name{
                color: #00D928;
            }
            button{
                width: 20%;
                border: none;
                background-color: inherit;
                border: 1px solid #00DA0F;
                color: #00DA0F;
                border-radius: 5px;
            }
        }
    }
}
.greenLine{
    border-top: 1px solid #00DA0F;
    flex-grow: 1;
}
.arrowLeft1,.arrowLeft2,.arrowLeft3,.arrowLeft4{
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 50%;
    left: 0%;
}
.arrowRight1,.arrowRight2,.arrowRight3,.arrowRight4{
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 50%;
    right: 0%;
}
.offer{
    &__block{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &__swiper{
            width: 90%;
            &__slide{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                text-align: center;
                &__name{
                    font-weight: 700;
                }
                img{
                    width: 100%;
                    aspect-ratio: 0.8;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }
    }
}
.line {
    width: 100%;
    border-top: 1px solid #0000001A;
}
.customTitle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-transform: uppercase;
}
.customTab {
    color: #000000 !important;
}
.saleCard{
    width: 49%;
    aspect-ratio: 2.43;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-image: url('@/assets/icons/test.png');
    color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    &::before{
        content: '';
        border-radius: 10px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.54) 0%, rgba(0, 0, 0, 0) 50.07%);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }
}
.schools {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;

    &__left {
        width: 20%;
    }

    &__right {
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__tabs {
                width: 70%;
                font-weight: 600;
                font-size: 36px;
                color: #00000026;
                display: flex;
                gap: 40px;
                white-space: nowrap;
                overflow: hidden;
                -webkit-overflow-scrolling: touch;

                &__in {
                    cursor: pointer
                }
            }

            &__right {
                width: 25%;
            }
        }

        &__block {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 2%;
            row-gap: 20px;

            &__sale {
                justify-content: space-between;

                &__top {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    &__title {
                        font-size: 36px;
                        font-weight: 700;
                        z-index: 2;
                    }

                    &__text {
                        font-size: 24px;
                        z-index: 2;
                    }
                }
            }
        }
    }
}
.redButton{
    padding: 15px 50px;
    width: fit-content;
    background-color: #FF0000;
    border: none;
    color: white;
    z-index: 2;
    border-radius: 5px;
}
.cert{
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    P{
        font-weight: 700;
        font-size: 36px;
        z-index: 2;
    }
}
.book{
    &__block{
        display: flex;
        justify-content: space-between;
        &__in{
            width: 18%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            img{
                width: 100%;
                aspect-ratio: 0.8;
                object-fit: cover;
                border-radius: 5px;
            }
            p{
                color: #0000008A;
            }
        }
    }
}
.contact{
    &__block{
        display: flex;
        justify-content: space-around;
        padding: 20px 0px;
        &__in{
            width: 22%;
            img{
                width: 40%;
                cursor: pointer;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 15px;
            font-weight: 700;
        }
    }
}
.test{
    &__block{
        display: flex;
        justify-content: space-around;
        &__in{
            width: 20%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            text-align: center;
            &__name{
                font-weight: 700;
            }
            &__bot{
                color: #0000008A;
            }
            img{
                width: 100%;
                aspect-ratio: 1 !important;
                height: auto;
                object-fit: cover;
            }
        }
    }
}
</style>
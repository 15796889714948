<template>
    <div class="modal1Back">
        <div class="modal1">
            <img src="@/assets/icons/exit.svg" class="exit" @click="closeModal()"/>
            <div class="modal1__top">
                <p class="modal1__top__title">Добавить программу</p>
                <p class="modal1__top__sub">Заполните все поля для добавления новой программы</p>
                <div class="line"></div>
            </div>
            <p class="customTitle">Название программы и категоризация</p>
            <div class="modal1__inputs">
                <div class="customInput">
                    <label>Название программы</label>
                    <input type="text" placeholder="University of London">
                </div>
                <div class="customInput">
                    <label>Стипендия</label>
                    <select id="country">
                        <option>Выберите категорию</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Степень</label>
                    <select id="country">
                        <option>Выберите степень</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Категория</label>
                    <select id="country">
                        <option>Выберите категорию</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Sub-category</label>
                    <select id="country">
                        <option>Выберите категорию</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Ссылка на сайт программы</label>
                    <input type="text" placeholder="Ссылка">
                </div>
                <div class="customInput">
                    <label>Продолжительность обучения</label>
                    <select id="country">
                        <option>1 год</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>GPA</label>
                    <select id="country">
                        <option>от 3 до 3.8</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>IELTS/TOEFL</label>
                    <select id="country">
                        <option>от 4 балов и выше</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Admission fee</label>
                    <select id="country">
                        <option>num</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>GRE/GMAT</label>
                    <select id="country">
                        <option>Требуется</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Tuition cost</label>
                    <select id="country">
                        <option>num</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Уровень</label>
                    <input type="text" placeholder="TOP-30">
                </div>
                <div class="customInput">
                    <label>Deadline 1</label>
                    <input type="text" placeholder="..">
                </div>
                <div class="customInput">
                    <label>Deadline 2</label>
                    <input type="text" placeholder="..">
                </div>
                <div class="customInput">
                    <label>Выберите страну</label>
                    <select id="country">
                        <option>США</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>State / province</label>
                    <select id="country">
                        <option>Delaware</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>City</label>
                    <select id="country">
                        <option>New Castle</option>
                    </select>
                </div>
            </div>
            <p class="customTitle">Медиа файлы программы</p>
            <div class="modal1__files">
                <div class="modal1__files__in" v-for="item in 3" :key="item">
                    <img src="@/assets/icons/program.png"/>
                </div>
                <label class="modal1__files__plus">
                    <input type="file" style="display: none;"/>
                    <img src="@/assets/icons/plus.svg"/>
                    <p>Добавить</p>
                </label>
            </div>
            <p class="customTitle">Описание программы</p>
            <div class="modal1__textarea">
                <textarea placeholder="..."></textarea>
            </div>
            <div class="modal1__documents">
                <div class="customInput">
                    <label>Академические требования</label>
                    <div>Список документов</div>
                </div>
                <div class="customInput">
                    <label>Перечень документов</label>
                    <div>Список документов</div>
                </div>
            </div>
            <div class="modal1__buttons">
                <button class="modal1__buttons__save">Опубликовать</button>
                <button class="modal1__buttons__delete">Предпросмотр</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
.modal1Back{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
    background-color: #0000007a;
}
.modal1{
    width: 64%;
    background: white;
    padding: 25px 60px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    border-radius: 25px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    .exit{
        position: absolute;
        top: 20px;
        cursor: pointer;
        right: 20px;
        filter: brightness(0) saturate(100%);
        width: 20px;
        height: 20px;
    }
    &__top{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: 100%;
        &__title{
            color: #11415D;
            font-size: 32px;
            font-weight: 600;
        }
        &__sub{
            color: #0000008A;
        }
    }
    &__inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: 25px;
    }
    &__files{
        display: flex;
        gap: 1%;
        align-items: center;
        &__in{
            width: 10%;
            img{
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                border-radius: 10px;
                cursor: pointer;
            }
        }
        &__plus{
            width: 10%;
            border-radius: 10px;
            cursor: pointer;
            color: #0000008A;
            aspect-ratio: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            background-color: #F0F2F5;
            align-items: center;
            justify-content: center;
            img{
                width: 30px;
                height: 30px;
            }
        }
    }
    &__documents{
        display: flex;
        justify-content: space-between;
        .customInput{
            width: 48%;
        }
    }
    &__textarea{
        width: 100%;
        textarea{
            width: 100%;
            height: 250px;
            resize: none;
            border: 1px solid #0000001A;
            border-radius: 10px;
            padding: 15px 25px;
        }
    }
    &__buttons{
        display: flex;
        gap: 2%;
        button{
            width: 30%;
            border: none;
            height: 50px;
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: 20px;
            background-color: #11415D;
            border: 1px solid #11415D;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2076a7;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: 20px;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }
}
.customTitle{
    font-size: 24px;
    font-weight: 600;
}
.customInput{
    position: relative;
    display: inline-block;
    width: 32%;
    cursor: pointer !important;
    label{
        position: absolute;
        top: -8px;
        left: 25px;
        background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: 14px;
        color: #c0c0c0; 
    }
    input, select {
        width: 100%;
        padding: 0px 25px;
        height: 50px;
        border: 1px solid #e0e0e0; /* Цвет рамки */
        border-radius: 8px;
        font-size: 20px;
        background-color: inherit;
        color: #000; /* Цвет текста */
        outline: none;
    }
    div{
        width: 100%;
        height: 300px !important;
        resize: none;
        border: 1px solid #0000001A;
        padding: 15px 25px;
        border-radius: 10px;
    }
    img{
        position: absolute;
        left: 15px;
        top: 50%;
        width: 5%;
        height: 10px;
        transform: translate(-50%, -50%);
        filter: brightness(0) saturate(100%) invert(46%) sepia(75%) saturate(3165%) hue-rotate(213deg) brightness(100%) contrast(103%);
    }
    }
</style>
<template>
  <div class="basket">
    <div class="basket-top">
      {{ savePrice }}
      <router-link to="/platform/programs"
        ><img src="@/assets/images/platform/red-arrow1.png" alt=""
      /></router-link>
      <p class="px-20 fw600 m_none">{{ this.$t("basket") }}</p>
      <p class="px-22 fw700 d_none">{{ this.$t("basket") }}</p>
    </div>
    <div class="basket-empty" v-if="this.getBasket?.length == 0">
      <p class="px-24 fw600">{{ this.$t("nonBasket") }}</p>
    </div>
    <div v-else class="basket-table">
      <div class="basket-table__top m_none">
        <p class="gray px-18 first" style="text-transform: uppercase">
          {{ this.$t("namePr") }}
        </p>
        <p class="gray px-18" style="text-transform: uppercase">
          {{ this.$t("piecePr") }}
        </p>
        <p class="gray px-18" style="text-transform: uppercase">
          {{ this.$t("pricePr") }}
        </p>
      </div>
      <div v-for="(item, index) in getBasket" :key="index">
        <div class="basket-table__item" v-if="item.type_name == 'subscription'">
          <basketItem class="cards" :item="item" />
          <div class="sub-counter">
            <button
              class="sub-counter__btn"
              v-for="(it, index) in item.price"
              :key="index"
              @click="setActive(index, it)"
              :class="{ activeBtn: index === activeIndex }"
            >
              {{ it.title }} мес
            </button>
          </div>
          <p v-if="activeItem !== null" class="price px-18 fw600 black">
            <span class="gray d_none">{{ this.$t("pricePr") }}: </span>
            {{ activeItem.price }} KZT
          </p>
          <p v-else class="price px-18 fw600 black">- KZT</p>
          <img
            class="remove-item"
            @click="removeSub(index)"
            src="@/assets/images/platform/logo/remove.png"
          />
        </div>
        <div class="basket-table__item" v-else>
          <basketItem class="cards" :item="item" />
          <div class="counter"></div>
          <p class="price px-18 fw600 black m_none">{{ item.price }} KZT</p>
          <p class="price px-12 gray d_none" style="display: flex">
            {{ this.$t("pricePr") }}:
            <span class="black fw500"
              >{{ item.price }} KZT/{{ this.$t("piece") }}</span
            >
          </p>
          <img
            class="remove-item"
            @click="removeItem(index)"
            src="@/assets/images/platform/logo/remove.png"
          />
        </div>
      </div>
    </div>
    <div class="pay" v-if="getBasket?.length !== 0">
      <div class="pay-top">
        <input type="checkbox" name="" id="1" v-model="checked" />
        <label for="1"
          >{{ this.$t("agree") }}
          <a :href="DOCS?.doc_3" style="color: #fd2d2a; font-weight: 700">{{
            this.$t("oferta")
          }}</a></label
        >
      </div>
      <div class="pay-bottom">
        <div class="px-24 fw500 gray">
          {{ this.$t("total") }}:
          <span v-if="activeItem !== null" class="fw700 black"
            >{{ total + activeItem.price }} KZT</span
          >
          <span v-else class="fw700 black">{{ total }} KZT</span>
        </div>
        <button class="px-24 fw700 white" @click="isBuy">
          {{ this.$t("buyPr") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import basketItem from "@/components/platform/basketItem.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: "top-right", duration: 2000 });

export default {
  components: { basketItem },
  data() {
    return {
      counter: 1,
      activeIndex: null,
      activeItem: null,
      type_time: null,
      checked: false,
    };
  },
  created() {
    this.GET_DOCS();
  },
  mounted() {
    const savedActiveIndex = localStorage.getItem("activeIndex");
    const savedPrice = JSON.parse(localStorage.getItem("activeIndexPrice"));
    if (savedActiveIndex !== null) {
      this.activeIndex = parseInt(savedActiveIndex);
    }
    if (savedPrice !== null) {
      this.activeItem = savedPrice;
    }
    console.log(savedPrice);
  },
  methods: {
    ...mapActions(["GET_DOCS"]),
    setActive(index, it) {
      this.activeIndex = index;
      localStorage.setItem("activeIndex", index);
      this.activeItem = it;
      localStorage.setItem("activeIndexPrice", JSON.stringify(it));
    },
    removeItem(index) {
      this.getBasket?.splice(index, 1);
      localStorage.setItem("basket", JSON.stringify(this.getBasket));
      toaster.error(this.$t("notRemBs"));
    },
    removeSub(index) {
      this.getBasket?.splice(index, 1);
      localStorage.setItem("basket", JSON.stringify(this.getBasket));
      localStorage.removeItem("activeIndexPrice");
      localStorage.removeItem("activeIndex");
      this.activeItem = null;
      this.activeIndex = null;
    },
    deleteAll() {
      this.$store.state.basket = [];
      localStorage.removeItem("basket");
      this.total = 0;
    },
    isBuy() {
      const hasSubscriptions = this.getBasket.some(
        (item) => item.type_name === "subscription"
      );

      if (hasSubscriptions) {
        const selectedMonth = this.activeItem?.title;
        this.getBasket.forEach((item) => {
          if (item.type_name === "subscription") {
            item.type_time = selectedMonth;
          }
        });
      }
      if (this.checked) {
        axios
          .post(
            "/platform/payment",
            {
              payment_card: "halykpay",
              products: this.getBasket,
            },
            {
              headers: {
                Authorization: localStorage.getItem("access_token")
                  ? `Bearer ${localStorage.getItem("access_token")}`
                  : null,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("res", response);
              let auth = response.data.data.token;
              let invoiceId = response.data.data.invoiceId;
              let amount = response.data.data.amount;
              halyk.showPaymentWidget(
                this.createPaymentObject(auth, invoiceId, amount),
                (res) => {
                  if (res.success) {
                    //   (this.isEnterBalance = false), this.GET_BALANCE();
                    this.deleteAll();
                  }
                }
              );
            } else if (response.status === 500) {
              toaster.error(this.$t("chooseTarif"));
            }
          })
          .catch((err) => {
            toaster.error(this.$t("chooseTarif"));
            console.log(err);
          });
      } else if (!this.checked) {
        toaster.error(this.$t("notAgree"));
      } else if (hasSubscriptions) {
        if (this.activeIndex == null) {
          toaster.error(this.$t("chooseTarif"));
        }
      }
    },
    minus() {
      if (this.counter > 1) {
        this.counter--;
        // this.saveCounterToLocalStorage();
      }
    },
    plus() {
      this.counter++;
      // this.saveCounterToLocalStorage();
    },
    createPaymentObject(auth, invoiceId, amount) {
      let paymentObject = {
        invoiceId: invoiceId,
        backLink: "",
        failureBackLink: "",
        postLink: "https://admin.mentorme.kz/api/V1/payment-result-success",
        failurePostLink:
          "https://admin.mentorme.kz/api/V1/payment-result-failed",
        language: this.lang === "ru" ? "rus" : "kaz",
        description: "Платёж Mentor-me",
        accountId: "toleBy48735637465",
        terminal: "67e34d63-102f-4bd1-898e-370781d0074d",
        amount: amount,
        currency: "KZT",
        // data: '{"statement":{"name":"Arman     Ali","invoiceID":"80000016"}}',
        cardSave: false, //Параметр должен передаваться как Boolean
      };
      paymentObject.auth = auth;
      return paymentObject;
    },
  },
  computed: {
    ...mapGetters(["getBasket", "DOCS"]),
    total() {
      let total = 0;

      for (const item of this.getBasket) {
        if (item.type_name === "subscription") {
          const itemTotal = item.price.reduce(
            (priceAccumulator, priceValue) =>
              priceAccumulator + priceValue.price,
            0
          );
        }
        // else if(item.type_name === "additional") {
        //   const itemTl = item.prices.reduce((priceAccumulator, priceValue) => priceAccumulator + priceValue.price, 0);
        // }
        else {
          total += Number(item.price);
          // console.log("asdasd", typeof(item.price));
        }
      }
      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/platform/main.scss";
.activeBtn {
  color: #fff !important;
  background: #fd2d2a !important;
}
.delete {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  color: #fd2d2a;
  font-weight: 600;
}
.sub {
  &-counter {
    &__btn {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
  }
}
.basket {
  padding: 24px 28px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px 0px 0px 0px;
  &-empty {
    text-align: center;
    padding: 50px 0;
  }
  &-top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 24px;
    a {
      img {
        // transform: rotate(180deg);
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
  &-table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 16px;
    &__item,
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        margin-right: 35px;
      }
      img {
        border-radius: 12px;
      }
      .counter {
        display: flex;
        align-items: center;
        .num {
          border-radius: 4px;
          background: #f3f3f3;
          margin: 0 14px;
          padding: 8px 0;
          width: 47px;
          text-align: center;
        }
        .plus,
        .minus {
          cursor: pointer;
        }
      }
    }
    &__top {
      width: 100%;
      margin-bottom: 40px;
      p {
        width: 33%;
        text-align: center;
      }
      .first {
        text-align: left;
      }
    }
    &__item {
      width: 100%;
      margin-bottom: 24px;
      position: relative;
      .cards,
      .counter,
      .price {
        width: 33%;
      }
      .counter {
        display: flex;
        justify-content: center;
      }
      .price {
        text-align: center;
      }
      .remove-item {
        position: absolute;
        width: 14px;
        height: 24px;
        object-fit: contain;
        cursor: pointer;
        right: 10px;
      }
    }
    &__title {
      margin: 30px 0 15px 0;
    }
  }
}
.pay {
  padding: 40px 0;
  &-items {
    margin-top: 28px;
    display: flex;
    .active {
      border: 1px solid #11415d;
      background-color: #11415d;
      p {
        color: #fff;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: #f5f7fb;
      width: 100px;
      padding: 12px 0 15px 0;
      transition: 0.4s;
      margin-right: 20px;
      // &:hover {
      //     transition: 0.4s;
      //     border: 1px solid #11415D;
      //     background-color: #11415D;
      //     p {
      //         color: #FFF;
      //     }
      //     svg {
      //         path {
      //             fill: #FFFFFF;
      //             stroke: #FFF;
      //         }
      //     }
      // }
      svg {
        width: 42px;
        height: 42px;
        margin-bottom: 8px;
      }
    }
  }
  &-cards {
    display: flex;
    margin: 35px 0 28px 0;
    &__item {
      margin-right: 38px;
      padding: 18px;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      width: 340px;
      transition: all 0.4s ease;
      cursor: pointer;
      &-top {
        margin-bottom: 56px;
      }
      .date {
        width: 65%;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
      }
      &:hover {
        background-color: #fd2d2a;
        & path {
          fill: white;
          fill-opacity: 1;
        }
        p {
          color: #fff;
        }
      }
    }
  }
  &-btn {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fd2d2a;
    padding: 10px 0;
    width: 204px;
    margin: 28px 0 42px 0;
    transition: all 0.4s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
  &-top {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    input {
      margin-right: 10px;
    }
    label {
      a {
        cursor: pointer;
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-top: 1px solid rgba(0, 0, 0, 0.1);
    // padding: 22px 0;
    button {
      padding: 15px 0;
      border-radius: 8px;
      background: #fd2d2a;
      border: none;
      width: 178px;
      transition: all 0.4s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &-inputs {
    margin-top: 32px;
    width: 100%;
    display: flex;
    &__item {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 24%;
      margin-right: 1%;
      label {
        position: absolute;
        left: 26px;
        top: -10px;
        padding: 0 4px;
        background: #fff;
        font-size: 14px;
        font-weight: 300;
        color: #8e8e8e;
      }
      input,
      select {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        color: #8e8e8e;
        padding: 14px 18px;
        font-size: 18px;
        font-weight: 500;
      }
      select {
        appearance: none;
        background: url("@/assets/images/platform/arr-gray.png") 96% / 20px
          no-repeat;
      }
    }
  }
  &-desc {
    margin-top: 28px;
  }
  &-textarea {
    margin-top: 28px;
    width: 100%;
    position: relative;
    textarea {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      resize: none;
      padding: 14px 18px;
      width: 100%;
      height: 150px;
      font-size: 18px;
      font-weight: 500;
      color: #8e8e8e;
    }
    label {
      position: absolute;
      left: 26px;
      top: -10px;
      padding: 0 4px;
      background: #fff;
      font-size: 14px;
      font-weight: 300;
      color: #8e8e8e;
    }
  }
}
@media (max-width: 960px) {
  .pay {
    padding: 10px 0;
    &-bottom {
      flex-direction: column;
      border-top: none;
      button {
        margin-top: 20px;
      }
    }
  }
  .basket {
    padding: 0;
    border: 0;
    &-table {
      &__item {
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 8px;
        .cards {
          width: 97%;
          padding-bottom: 8px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .remove-item {
          bottom: 8px;
        }
        .price {
          margin-top: 10px;
          text-align: left;
          width: 100%;
          font-size: 12px;
        }
        .sub {
          &-counter {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="backgroundSign block animated fadeIn"
    v-if="$store.state.openMentorModal"
    @click="$store.state.openMentorModal = false"
  >
    <form class="sign" @click.stop @submit.prevent="signIn" v-if="selectedItem">
      <img @click="$store.state.openMentorModal = false" class="close-form" src="@/assets/images/close-white.png" alt="close">
      <div class="sign__mentor">
        <img
          class="sign__mentor-img"
          :src="selectedItem.image"
          alt=""
        />
        <div class="sign__mentor-line"></div>

        <h2 class="sign__mentor-name" v-html="selectedItem.full_name"></h2>

        <div class="sign__mentor__info">
          <p class="sign__mentor__info-text g16-500">
            Опыт работы:
            <span class="sign__mentor__info-text2 k16-400" v-html="selectedItem.experience_work"></span>
          </p>
        </div>

        <div class="sign__mentor__info">
          <p class="sign__mentor__info-text g16-500">
            Опыт в организациях:
            <span class="sign__mentor__info-text2 k16-400"
            v-html="selectedItem.experience_company"></span
            >
          </p>
        </div>

        <div class="sign__mentor__info">
          <p class="sign__mentor__info-text g16-500">
            Индустрии:
            <span class="sign__mentor__info-text2 k16-400"
              v-html="selectedItem.industry" ></span
            >
          </p>
        </div>

        <div class="sign__mentor__info">
          <p class="sign__mentor__info-text g16-500">
            Краткое био:
            <span class="sign__mentor__info-text2 k16-400"
            v-html="selectedItem.biography"></span
            >
          </p>
        </div>
      </div>

      <a
        class="sign-registrBtn k16-700"
        href="https://api.whatsapp.com/send?phone=77007187878" 
        target="_blank"
        @click="sendWhatsapp"
      >
        {{ this.$t("bron") }} {{ selectedItem.reserve_price }} USD
      </a>
    </form>
  </div>
</template>
<script>

export default {
  props: ["selectedItem"],
 
  data() {
    return {

    }
  },
};
</script>

<style lang="scss" scoped>
.close {
  &-form {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
.valid {
  color: red;
  text-align: start;
}
.block {
  display: none; /* блок по умолчанию скрыт */
}
.animated {
  animation-duration: 1s; /* длительность анимации */
  animation-fill-mode: both; /* сохраняем состояние после анимации */
}
.fadeIn {
  animation-name: fadeIn; /* имя анимации */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.backgroundSign {
  color: black;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign {
  width: 390px;
  background: #11415d;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 74px 14px 14px;
  border-radius: 8px;
  position: relative;

  &__mentor {
    position: relative;
    width: 362px;
    height: fit-content;
    background: #ffffff;
    border-radius: 5px;
    padding: 12px;
    &-line {
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 384px;
      margin: 79px 0px 0px;
      width: 100%;
      position: relative;
    }
    &-img {
      position: absolute;
      top: -60px;
      left: 120px;
      width: 120px;
      height: 120px;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 4px;
      object-fit: cover;
    }
    &-name {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #393939;
      padding-bottom: 40px;
      margin-top: 14px;
    }
    &__info {
      padding-bottom: 12px;
      &-text {
        padding-right: 5px;
        letter-spacing: 0.02em;
      }
    }
  }

  &-registrBtn {
    height: 48px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    padding: 13px;
    width: 361px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 13px;
  }
}
</style>

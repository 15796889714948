import { createI18n } from "vue-i18n"; 
 
export default createI18n({ 
  locale: localStorage.getItem("lang") || "ru", 
  fallbackLocale: "en", 
  messages: { 
    kz: require("./locales/kz.json"), 
    en: require("./locales/en.json"), 
    ru: require("./locales/ru.json"), 
  }, 
});
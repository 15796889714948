<template>
    <div class="grant">
        <div class="grant__left">
            <platformTable />
        </div>
        <div class="grant__right">
            <div class="grant__right__top">
                <div class="grant__right__top__title">
                    Международные гранты
                </div>
                <div class="grant__right__top__right">
                   <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="world">
                <div class="world__left">
                    <p class="customTitle">Выберите международную стипендию</p>
                    <div class="world__left__block">
                        <div class="world__left__block__in" v-for="(item, index) in 5" :key="item" @click="selectStip(index)" :class="{customStip: selectedStip == index}">
                            <div class="world__left__block__in__top">
                                <img src="@/assets/icons/bolashak.svg"/>
                            </div>
                            <p>Весь мир</p>
                        </div>
                    </div>
                </div> 
                <div class="world__right">
                    <p class="customTitle">Выберите степень</p>
                    <div class="world__right__block">
                        <div class="world__right__block__in1" v-for="(item, index) in 3" :key="item" @click="selectStep(index)" :class="{customStip: selectedStep == index}">
                            <div class="world__left__block__in__top">
                                <img src="@/assets/icons/magistratura.svg"/>
                            </div>
                            <p>Бакалавриат</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="grant__right__block">
                <div class="grant__right__block__in" v-for="item in 4">
                    <grantCard />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import grantCard from '@/components/grantCard.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
export default {
    components:{
        platformTable, Swiper, SwiperSlide, newCardComponent,userInfo, grantCard
    },
    data(){
        return{
            activeTab: 'list',
            selectedStip: 0,
            selectedStep: 0,
        }
    },
    methods:{
        selectStep(index){
            if(this.selectedStep == index){
                this.selectedStep = -1
            }
            else{
                this.selectedStep = index
            }
        },selectStip(index){
            if(this.selectedStip == index){
                this.selectedStip = -1
            }
            else{
                this.selectedStip = index
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.customTitle{
    color: #0000008A;
    font-size: 24px;
    font-weight: 600;
}
.customStip{
    color: white !important;
    background: #FD2D2A !important; 
    font-weight: 600;
    .world__left__block__in__top{
        background-color: white !important;
    }
    img{
        filter: brightness(0) saturate(100%) invert(41%) sepia(98%) saturate(5156%) hue-rotate(345deg) brightness(97%) contrast(105%) !important;
    }
    .category__block__in__top{
        background-color: white !important;
    }
    .line{
        border-top: 1px solid white !important;
    }
}
.world{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &__left{
        width: 60%;
        &__block{
            gap: 2.5%;
            &__in{
                &__top{
                    padding: 15px 10px;
                    width: 100%;
                    border-radius: 10px;
                    img{
                        width: 100%;
                    }
                    border: 1px solid #0000001A;
                }
                width: 18%;
            }
        }
    }
    &__right{
        width: 32%;
        &__block{
            gap: 5%;
            &__in1{
                width: 30%;
            }
        }
    }
    &__left, &__right{
        display: flex;
        flex-direction: column;
        gap: 25px;
        &__block{
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            &__in, &__in1{
                cursor: pointer;
                display: flex;
                padding: 8px 8px 10px;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                font-weight: 600;
                img{
                    height: 35px;
                }
            }
            &__in1{
                background-color: #FFFFFF;
                img{
                    height: 35px;
                    filter: brightness(0) saturate(100%) invert(71%) sepia(1%) saturate(503%) hue-rotate(114deg) brightness(98%) contrast(96%);
                }
            }
            &__in{
                color: #FD2D2A;
                background: white;
                img{
                    filter: brightness(0) saturate(100%) invert(64%) sepia(84%) saturate(7188%) hue-rotate(344deg) brightness(102%) contrast(105%);
                }
                .line{
                    border-top: 1px solid #FD2D2A;
                }
            }
        }
    }
}
.grant{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;
    &__left{
        width: 20%;
    }
    &__right{
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__title{
                font-size: 36px;
                font-weight: 600;
            }
            &__right{
                width: 25%;
            }
        }
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 2.5%;
            &__in{
                width: 18%;
            }
        }
    }
}
</style>
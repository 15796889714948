<template>
    <div class="modal1" @mouseenter="isBig = true" @mouseleave="isBig = false">
        <div class="modal1__mini" v-if="isBig == false">
            <p class="modal1__mini__text">Прогресс менторства</p>
            <p class="modal1__mini__percentage">80% done</p>
        </div>
        <div class="modal1__big" v-else>
            <p class="modal1__big__text">Прогресс менторства</p>
            <div class="modal1__big__progress">
                <p class="modal1__big__progress__title">Выполнено работы</p>
                <div class="modal1__big__progress__block">
                    <div class="modal1__big__progress__block__in">
                    </div>
                </div>
                <p class="modal1__big__progress__percentage">80%</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
data(){
    return{
        isBig: false
    }
}
}
</script>
<style lang="scss" scoped>
.modal1{
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: fit-content;
    background-color: white;
    box-shadow: 0px 4px 4px 2px #0000001A;
    z-index: 2;
    border-radius: 25px;
    height: 120px;
    cursor: pointer;
    &__mini{
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        gap: 10px;
        align-items: center;
        &__text{
            font-size: 18px;
            color: #0000008A;
        }
        &__percentage{
            color: #FD2D2A;
            font-size: 32px;
            font-weight: 500;
        }
    }
    &__big{
        width: 600px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__text{
            font-size: 18px;
            color: #0000008A;
        }
        &__progress{
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;
            p{
                color: #FD2D2A;
                font-size: 24px;
            }
            &__block{
                height: 20px;
                border: 1px solid #0000001A;
                border-radius: 20px;
                width: 100%;
                &__in{
                    background-color: #FD2D2A;
                    width: 80%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
            &__percentage{
                position: absolute;
                top: 0px;
                left: calc(80% - 24px);
            }
        }
    }
}
</style>
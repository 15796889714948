<template>
  <div class="scholarshipPlus" v-if="MAIN_PAGE">
    <div class="container">
      <div class="scholarshipPlus__main" v-if="MAIN_PAGE.homeStep">
        <h1
          class="scholarshipPlus__main-title"
          v-html="MAIN_PAGE.homeStep.title"
        ></h1>
        <h3
          class="scholarshipPlus__main-text"
          v-html="MAIN_PAGE.homeStep.description"
        ></h3>
      </div>
      <div class="scholarshipPlus__cards d-flex" v-if="MAIN_PAGE.homeStepItems">
        <div
          class="scholarshipPlus__card"
          v-for="(item, index) in MAIN_PAGE.homeStepItems"
          :key="index"
        >
          <div class="scholarshipPlus__card-top">
            <div class="scholarshipPlus__card-num d-flex-center">
              <h1 class="scholarshipPlus__card-num1">{{ item.step }}</h1>
              <p class="scholarshipPlus__card-num2" v-html="item.title"></p>
            </div>
            <div class="scholarshipPlus__card-text-wrapper">
              <p class="scholarshipPlus__card-text" v-html="item.description"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="scholarshipPlus__btn">
        <router-link to="/price">
        <button>{{ this.$t("more") }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["MAIN_PAGE"],
};
</script>
<style lang="scss" scoped>
.scholarshipPlus {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.54) 0%,
    rgba(0, 0, 0, 0) 0%
  );
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px;

    & button {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #11415d;
      background-color: #ffffff;
      border-radius: 8px;
      border: none;
      padding: 16px 28px;
      transition: 0.4s;
      &:hover {
        background-color: red;
        color: white;
        transition: 0.4s;
      }
    }
  }
  &__main {
    padding: 80px 0px;
    &-title {
      font-weight: 700;
      font-size: 36px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
      padding-bottom: 18px;
    }
    &-text {
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
    }
  }
  &__cards {
    gap: 30px;
  }
  &__card {
    width: 386px;
    height: 258px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(24px);
    border-radius: 18px;
    // &-top {
    // }
    &-num {
      padding: 28px 46px 28px 28px;
    }
    &-num1 {
      padding-right: 24px;
      font-weight: 800;
      font-size: 48px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
      width: 62px;
      height: 62px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 17px 21px;
      margin-right: 24px;
      display: flex;
      justify-content: center;
    }
    &-num2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    &-text-wrapper {
      max-height: 110px;
      overflow: hidden;
      position: relative;
    }
    &-text {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.02em;
      color: rgba(255, 255, 255, 0.54);
      padding: 0px 28px 5px;
      // overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: rgba(255, 255, 255, 0.1) rgba(0, 0, 0, 0.3);
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .scholarshipPlus {
    &__cards {
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__card {
      max-width: 368px;
      // height: auto;
      &-num {
        padding: 28px 36px 28px 18px;
      }
      &-num2 {
        font-size: 18px;
      }
      &-text {
        -webkit-line-clamp: 5;
        font-size: 15px;
        &-wrapper {
          max-height: 110px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .scholarshipPlus {
    // border-top: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 2px;
    &__cards {
      gap: 35px;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__main {
      padding: 18px 0px;

      &-title {
        display: none;
      }
      &-text {
        display: none;
      }
    }

    &__card {
      width: calc(50% - 20px);
      height: 272px;

      &-num {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 8px;
      }
      &-num1 {
        font-size: 24px;
        padding: 8px 10px;
        margin-right: 0;
        width: 45px;
        height: 45px;
      }
      &-num2 {
        font-size: 16px;
        text-align: center;
      }
      &-text {
        font-size: 14px;
        padding: 0px 8px 8px 8px;
        text-align: center;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        &-wrapper {
          max-height: 150px;
        }
      }
    }
    &__btn {
      padding: 28px 0;
      button {
        width: 180px;
        padding: 10px 0;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
      }
    }
  }
}
</style>

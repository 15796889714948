<template>
    <div class="custom-select" @click="toggleDropdown">
      <div class="custom-select-trigger">
        <p>{{ selectedLabel }}</p>
        <img src="@/assets/icons/arrow.svg"/>
      </div>
      <div v-if="isOpen" class="custom-select-options">
        <div
          v-for="option in options"
          :key="option.value"
          class="custom-select-option"
          :class="{ selected: option.value === value }"
          @click="selectOption(option.value)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomSelect",
    props: {
      value: {
        type: [String, Number],
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    computed: {
      selectedLabel() {
        const selected = this.options.find((option) => option.value === this.value);
        return selected ? selected.label : "Выберите...";
      },
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      selectOption(value) {
        this.$emit("input", value); // Обновляем v-model
        this.isOpen = false;
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
    border: 1px solid #0000001A;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .custom-select-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    background: #fff;
    user-select: none;
    img{
        width: 15px;
        height: 15px;
        rotate: 270deg;
    }
  }
  
  .custom-select-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #0000001A;
    border-top: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .custom-select-option {
    padding: 10px;
    cursor: pointer;
  }
  
  .custom-select-option:hover,
  .custom-select-option.selected {
    background: #f0f0f0;
  }
  </style>
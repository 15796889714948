<template>
  <Header/>
  <div class="plat">
    <div class="plat-wrap">
        <div class="plat-left m_none">
            <p class="plat-left__title gray px-18 fw400">{{ $t("main") }}</p>
            <div class="plat-left__box">
                <NavigationBar/>
            </div>
        </div>
        <div class="plat-right">
            <router-view></router-view>
        </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/platform/Header.vue'
import NavigationBar from '@/components/platform/NavigationBar.vue'
export default {
  components: { Header, NavigationBar },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/platform/main.scss';
@import '@/assets/styles/platform/platformPage.scss';

</style>
<template>
    <div class="modal1Back">
        <div class="modal1">
            <div class="modal1__top">
                <div class="modal1__top__left">
                    <img src="@/assets/icons/curator.svg"/>
                    <p>ID 456</p>
                </div>
                <div class="modal1__top__right">
                    <p class="modal1__top__right__name">Джеймс Д.</p>
                    <div class="modal1__top__right__status">
                        <img src="@/assets/icons/curatorIcon.svg"/>
                        <p>Куратор</p>
                    </div>
                    <div class="line"></div>
                    <p class="modal1__top__right__email">mail@mentorme.com</p>
                    <p class="modal1__top__right__phone">+1 540 44 44</p>
                </div>
            </div>
            <div class="modal1__info">
                <p class="modal1__info__title">Редактирование данных</p>
                <p class="modal1__info__sub">Информация устарела? Без проблем, укажите новую!</p>
            </div>
            <div class="modal1__inputs">
                <div class="customInput">
                    <label>Фамилия</label>
                    <input placeholder="Джонсон"/>
                </div>
                <div class="customInput">
                    <label>Имя</label>
                    <input placeholder="Джеймс"/>
                </div>
                <div class="customInput">
                    <label>Email</label>
                    <select>
                        <option>mail@mentor...</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Email</label>
                    <select>
                        <option>+1 540 44 44</option>
                    </select>
                </div>
            </div>
            <div class="modal1__buttons">
                <button class="modal1__buttons__save">Применить</button>
                <button class="modal1__buttons__delete" @click="closeModal()">Закрыть</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
.modal1Back{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
    background-color: #0000007a;
}
.modal1{
    width: 33%;
    background: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    border-radius: 25px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    &__top{
        display: flex;
        justify-content: space-between;
        &__left{
            width: 30%;
            aspect-ratio: 1;
            position: relative;
            border-radius: 50%;
            &::before{
                content: '';
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            p{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-weight: 600;   
            }
            img{
                width: 100%;
                height: auto;
            }
        }
        &__right{
            width: 65%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            &__name{
                font-size: 32px;
                color: #11415D;
                font-weight:700;
            }
            &__status{
                img{
                    width: 15px;
                }
                display: flex;
                gap: 10px;
                align-items: center;
                color: #FD2D2A;
                font-weight: 600;
            }
            &__email, &__phone{
                color: #0000008A;
                font-weight: 600;
            }
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__title{
            font-weight: 600;
            font-size: 24px;
            color: #11415D;
        }
        &__sub{
            color: #0000008A;
        }
    }
    &__inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: 25px;
    }
        &__buttons{
            display: flex;
            gap: 4%;
            button{
                width: 48%;
                border: none;
                height: 50px;
                border-radius: 10px;
            }
            &__save{
                color: white;
                font-size: 20px;
                background-color: #11415D;
                border: 1px solid #11415D;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #2076a7;
                }
            }
            &__delete{
                background-color: inherit;
                border: 1px solid #0000001A !important;
                color: #0000008A;
                font-size: 20px;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
}
.customInput{
    position: relative;
    display: inline-block;
    width: 48%;
    cursor: pointer !important;
    label{
        position: absolute;
        top: -8px;
        left: 25px;
        background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: 14px;
        color: #c0c0c0; 
    }
    input, select {
        width: 100%;
        padding: 0px 25px;
        height: 50px;
        border: 1px solid #e0e0e0; /* Цвет рамки */
        border-radius: 8px;
        font-size: 20px;
        background-color: inherit;
        color: #000; /* Цвет текста */
        outline: none;
    }
    }
</style>
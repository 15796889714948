<template>
    <div class="find">
        <div class="find__left">
            <platformTable />
        </div>
        <div class="find__right">
            <div class="find__right__top">
                <div class="find__right__top__title">
                    Поиск программ
                </div>
                <div class="find__right__top__right">
                   <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="world">
                <div class="world__left">
                    <p class="customTitle">Выберите международную стипендию</p>
                    <div class="world__left__block">
                        <div class="world__left__block__in" v-for="(item, index) in 5" :key="item" @click="selectStip(index)" :class="{customStip: selectedStip == index}">
                            <div class="world__left__block__in__top">
                                <img src="@/assets/icons/bolashak.svg"/>
                            </div>
                            <p>Весь мир</p>
                        </div>
                    </div>
                </div> 
                <div class="world__right">
                    <p class="customTitle">Выберите степень</p>
                    <div class="world__right__block">
                        <div class="world__right__block__in1" v-for="(item, index) in 3" :key="item" @click="selectStep(index)" :class="{customStip: selectedStep == index}">
                            <div class="world__left__block__in__top">
                                <img src="@/assets/icons/magistratura.svg"/>
                            </div>
                            <p>Бакалавриат</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="category">
                <p class="customTitle">Выберите категорию</p>
                <div class="category__block">
                    <div class="category__block__in" v-for="(item, index) in category" :key="item" @click="selectCat(index)" :class="{customStip: selectedCat == index}">
                        <div class="category__block__in__top">
                            <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                        </div>
                        <p>{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <div class="inputs">
                <div class="customInput">
                    <label>Выберите подкатегорию</label>
                    <select>
                        <option>Все</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Выберите страну</label>
                    <select>
                        <option>США</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>GPA</label>
                    <select>
                        <option>от 3 до 3.8</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>IELTS</label>
                    <select>
                        <option>от 4 балов и выше</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>GRE/GMAT</label>
                    <select>
                        <option>Требуется</option>
                    </select>
                </div>
                <div class="customInput">
                    <label>Продолжительность обучения</label>
                    <select>
                        <option>1 год</option>
                    </select>
                </div>
            </div>
            <div class="search">
                <div class="search__title">
                    <div class="line"></div>
                    <p>Результаты поиска</p>
                    <div class="line"></div>
                </div>
                <div class="search__top">
                    <div class="search__top__left">
                        <input type="search" placeholder="Поиск программ...."/>
                        <img src="@/assets/icons/find.svg"/>
                    </div>   
                    <div class="search__top__right">
                        <p>Сортировка:</p>
                        <select>
                            <option>По алфавиту</option>
                            <option>По буквам</option>
                            <option>По id</option>
                        </select>
                    </div>
                </div>
                <div class="search__results">
                    <div class="search__results__in" v-for="item in 8">
                        <newCardComponent />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import newCardComponent from '@/components/newCardComponent.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
import userInfo from '@/components/userInfo.vue';
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
export default {
    components:{
        platformTable, Swiper, SwiperSlide, newCardComponent,userInfo
    },
    data(){
        return{
            activeTab: 'list',
            selectedStip: 0,
            selectedStep: 0,
            selectedCat: -1,
            category:[
                {
                    img: 'category1',
                    name: 'Tech & Policy'
                },
                {
                    img: 'category2',
                    name: 'Fine Arts'
                },
                {
                    img: 'category3',
                    name: 'Engineering'
                },
                {
                    img: 'category4',
                    name: 'Social studies'
                },
                {
                    img: 'category5',
                    name: 'Craftsmanship'
                },
                {
                    img: 'category6',
                    name: 'Marketing'
                },
                {
                    img: 'category7',
                    name: 'Coming soon'
                },
                {
                    img: 'category8',
                    name: 'Показать все'
                },
            ]
        }
    },
    methods: {
        selectStep(index){
            if(this.selectedStep == index){
                this.selectedStep = -1
            }
            else{
                this.selectedStep = index
            }
        },selectStip(index){
            if(this.selectedStip == index){
                this.selectedStip = -1
            }
            else{
                this.selectedStip = index
            }
        },selectCat(index){
            if(this.selectedCat == index){
                this.selectedCat = -1
            }
            else{
                this.selectedCat = index
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.customTitle{
    font-size: 24px;
    color: #0000008A;
    font-weight: 600;
}
.customStip{
    color: white !important;
    background: #FD2D2A !important; 
    font-weight: 600;
    .world__left__block__in__top{
        background-color: white !important;
    }
    img{
        filter: brightness(0) saturate(100%) invert(41%) sepia(98%) saturate(5156%) hue-rotate(345deg) brightness(97%) contrast(105%) !important;
    }
    .category__block__in__top{
        background-color: white !important;
    }
    .line{
        border-top: 1px solid white !important;
    }
}
.world{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    &__left{
        width: 60%;
        &__block{
            gap: 2.5%;
            &__in{
                &__top{
                    padding: 15px 10px;
                    width: 100%;
                    border-radius: 10px;
                    img{
                        width: 100%;
                    }
                    border: 1px solid #0000001A;
                }
                width: 18%;
            }
        }
    }
    &__right{
        width: 32%;
        &__block{
            gap: 5%;
            &__in1{
                width: 30%;
            }
        }
    }
    &__left, &__right{
        display: flex;
        flex-direction: column;
        gap: 25px;
        &__block{
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            &__in, &__in1{
                cursor: pointer;
                display: flex;
                padding: 8px 8px 10px;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                font-weight: 600;
                img{
                    height: 35px;
                }
            }
            &__in1{
                background-color: #FFFFFF;
                img{
                    height: 35px;
                    filter: brightness(0) saturate(100%) invert(71%) sepia(1%) saturate(503%) hue-rotate(114deg) brightness(98%) contrast(96%);
                }
            }
            &__in{
                color: #FD2D2A;
                background: white;
                img{
                    filter: brightness(0) saturate(100%) invert(64%) sepia(84%) saturate(7188%) hue-rotate(344deg) brightness(102%) contrast(105%);
                }
                .line{
                    border-top: 1px solid #FD2D2A;
                }
            }
        }
    }
}
.category{
    display: flex;
    flex-direction: column;
    gap: 25px;
    &__block{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        &__in{
            width: 11%;
            background-color: white;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            padding: 8px 8px 10px;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;
            &__top{
                padding: 15px 10px;
                width: 100%;
                border-radius: 10px;
                display: flex;
                height: 60px;
                align-items: center;
                justify-content: center;
                img{
                    width: 30px;
                }
                border: 1px solid #0000001A;
            }
        }
    }
}
.find{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;
    &__left{
        width: 20%;
    }
    &__right{
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__title{
                font-size: 36px;
                font-weight: 600;
            }
            &__right{
                width: 25%;
            }
        }
    }
}
.inputs{
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: 20px;
}
.customInput{
  position: relative;
  display: inline-block;
  width: 23.5%;
  label{
    position: absolute;
    top: -8px;
    left: 25px;
    background-color: #F5F7FB; /* Цвет фона, чтобы совпадал с фоном */
    padding: 0 10px;
    font-size: 14px;
    color: #c0c0c0; 
  }
  input, select {
    width: 100%;
    cursor: pointer !important;
    padding: 0px 25px;
    height: 50px;
    border: 1px solid #e0e0e0; /* Цвет рамки */
    border-radius: 8px;
    font-size: 20px;
    background-color: inherit;
    font-weight: bold;
    color: #000; /* Цвет текста */
    outline: none;
  }
}
.activeTab{
    background-color: white !important;
}
.search{
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__title{
        display: flex;
        justify-content: space-between;
        color: rgba(0, 0, 0, 0.541176);
        align-items: center;
        text-transform: uppercase;
        font-size: 24px;
        .line{
            width: 36%;
        }
    }
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        &__left{
            width: 78%;
            position: relative;
            input{
                width: 100%;
                padding: 15px 25px;
                border: 1px solid #0000001A;
                background-color: inherit;
                border-radius: 10px;
                font-size: 20px;
                font-style: italic;
            }
            img{
                width:25px;
                position: absolute;
                right: 15px;
                cursor: pointer;
            }
        }
        &__right{
            max-width: 20%;
            display: flex;
            cursor: pointer;
            gap: 5px;
            border-radius: 10px;
            align-items: center;
            gap: 10px;
            border: 1px solid #0000001A;
            padding: 0px 10px;
            p{
                color: #0000008A;
            }
            select{
                border: none;
                background-color: transparent;
            }
        }
    }
    &__results{
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        row-gap: 30px;
        &__in{
            width: 48%;
        }
    }
}
</style>
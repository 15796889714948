<template>
  <div class="service">
    <div class="service-top">
      <p class="service-top__title px-20 fw600 m_none">{{ $t("ourServ") }}:</p>
      <p class="service-top__title px-22 fw700 d_none">{{ $t("ourServ") }}</p>
      <div class="product-top__select m_none">
        <ul class="mobile-options">
          <li
            class="px-18 gray"
            v-for="option in options"
            :class="{ active: selectedOption == option }"
            :key="option"
            @click="selectOption(option)"
          >
            <img
              style="width: 22px; height: 22px"
              src="@/assets/images/platform/video-icon.svg"
              alt=""
            />
            {{ option }}
          </li>
        </ul>
      </div>
      <div class="service-top__select d_none">
        <div
          class="service-top__select-option gray px-18"
          @click="toggleDropdown"
        >
          <div class="wrap">
            <img src="@/assets/images/platform/video-icon.png" alt="" />
            {{ selectedOption }}
          </div>
          <img
            :class="{ rotate: isDropdownOpen }"
            src="@/assets/images/platform/arr-gray.png"
            alt=""
          />
        </div>
        <ul v-if="isDropdownOpen" class="options filter-full__btm-opt">
          <li
            class="option px-18 gray"
            v-for="option in options"
            :key="option"
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </div>
    <div
      class="service-btm__wrap"
      v-if="this.selectedOption == this.$t('careerMentor')"
    >
      <p class="px-22 fw700 d_none">{{ this.$t("careerMentor") }}</p>
      <div class="service-btm__selects" v-if="SERVICE_MENTORS">
        <div
          class="select"
          v-for="(item, index) in SERVICE_MENTORS.specifications"
          :key="index"
        >
          <select
            name=""
            id=""
            v-model="specification_item_ids[index]"
            @change="getMentors"
          >
            <option value="" selected disabled>{{ item.title }}</option>
            <option
              v-for="(it, index) in item.specificationItems"
              :key="index"
              :value="it.id"
            >
              {{ it.title }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="SEARCH_RESULT">
        <div class="service-btm">
          <div
            class="service-btm__item"
            v-for="(item, index) in SEARCH_RESULT?.data"
            :key="index"
            @click="
              ($store.state.openMentorModal = true), (selectedItem = item)
            "
          >
            <p class="px-16 gray fw400">{{ item.direction }}</p>
            <img :src="item.image" alt="" />
          </div>
        </div>
      </div>
      <div class="" v-else>
        <div class="service-btm" v-if="SERVICE_MENTORS">
          <div
            class="service-btm__item"
            v-for="(item, index) in SERVICE_MENTORS?.mentors"
            :key="index"
            @click="getModal(item)"
          >
            <p class="px-16 gray fw400">{{ item.direction }}</p>
            <img :src="item.image" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="service-dop__wrap"
      v-if="this.selectedOption == this.$t('addService')"
    >
      <p class="px-22 fw700 d_none">{{ this.$t("addService") }}</p>
      <div class="service-dop" v-if="SERVICE_ADD">
        <service-card
          v-for="(item, index) in SERVICE_ADD"
          :key="index"
          :card="item"
        />
      </div>
    </div>
    <mentor-modal :selectedItem="selectedItem" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ServiceCard from "@/components/platform/ServiceCard.vue";
import mentorModal from "@/components/modals/mentorModal.vue";
export default {
  components: { ServiceCard, mentorModal },
  data() {
    return {
      isDropdownOpen: false,
      selectedOption: this.$t("addService"),
      options: [this.$t("careerMentor"), this.$t("addService")],
      isShow: [],
      specification_item_ids: ["", "", "", ""],
      search_input: null,
      activeSearch: false,
      selectedItem: "",
    };
  },
  created() {
    this.GET_SERVICE_MENTORS();
    this.GET_SERVICE_ADD();
  },
  methods: {
    ...mapActions([
      "GET_SERVICE_MENTORS",
      "GET_SERVICE_ADD",
      "GET_SEARCH_RESULT",
    ]),
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getModal(item) {
      this.selectedItem = item;
      this.$store.state.openMentorModal = true;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isDropdownOpen = false;
    },
    async getMentors() {
      await this.GET_SEARCH_RESULT({
        specification_item_ids: this.specification_item_ids
          .filter(Boolean)
          .join(","),
      });
      // await this.GET_SERVICE_MENTORS({
      //   specification_item_ids : this.specification_item_ids
      //   .filter(Boolean)
      //   .join(","),
      // })
    },
  },
  computed: {
    ...mapGetters(["SERVICE_MENTORS", "SERVICE_ADD", "SEARCH_RESULT"]),
  },
  watch: {
    specification_item_ids() {
      this.getMentors();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/styles/platform/main.scss");
.rotate {
  transform: rotate(180deg) !important;
  transition: all ease 0.4s;
}
.service {
  padding: 24px 28px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px 0px 0px 0px;
  min-height: 100vh;
  &-top {
    display: flex;
    align-items: baseline;
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &__title {
      margin-right: 18px;
    }
    &__select {
      width: 360px;
      position: relative;
      &-option {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        img {
          width: 20px;
          height: 20px;
          transition: all ease 0.4s;
        }
        .wrap {
          display: flex;
          align-items: center;
          img {
            margin-right: 8px;
          }
        }
      }
      .options {
        position: absolute;
        z-index: 3;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 0 12px;
        width: 360px;
      }
    }
  }
  &-btm {
    margin-top: 28px;
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(16%, 1fr));
    // gap: 15px;
    &__item {
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      width: 16%;
      margin-right: 1%;
      padding: 8px;
      margin-bottom: 24px;
      transition: 0.4s;
      position: relative;
      cursor: pointer;
      .blur {
        position: absolute;
        z-index: 3;
        letter-spacing: 0.36px;
        transition: all ease 0.4s;
        top: 40%;
        left: 15%;
      }
      &:hover {
        transition: 0.4s;
        transform: scale(1.1);
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 256px;
        border-radius: 12px;
        margin-top: 8px;
      }
    }
    &__selects {
      display: flex;
      flex-wrap: wrap;
      padding: 24px 0 12px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      select {
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.54);
        font-size: 18px;
        padding: 13px 50px 13px 12px;
        margin-right: 12px;
        margin-bottom: 12px;
        appearance: none;
        background: url("@/assets/images/platform/arr-gray.png") 96% / 20px
          no-repeat;
        option {
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
  &-dop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &__wrap {
      padding-top: 28px;
    }
    &__item {
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px;
      width: 98%;
      margin-bottom: 28px;
      img {
        // width: 462px;
        height: 140px;
        padding: 8px 0;
      }
      .price {
        text-align: center;
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
        span {
          color: #fd2d2a;
          font-weight: 700;
        }
      }
      hr {
        color: rgba(0, 0, 0, 0.54);
        margin: 8px 0;
      }
      .btm {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 24px;
        select,
        button {
          width: 49%;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 8px;
          color: rgba(0, 0, 0, 0.54);
          font-size: 18px;
          transition: 0.4s;
        }
        select {
          appearance: none;
          background: url("@/assets/images/platform/arr-gray.png") 96% / 20px
            no-repeat;
        }
        button {
          &:hover {
            background: #fd2d2a;
            color: #fff;
            transition: 0.4s;
          }
        }
      }
      &-desc {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
.option {
  img {
    width: 22px;
    height: 22px;
  }
  padding: 9px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
ul {
  list-style-type: none;
  padding-left: 0;
}
@media (min-width: 960px) and (max-width: 1440px) {
  .service {
    &-btm {
      &__item {
        width: 19%;
        img {
          height: 200px;
        }
      }
    }
  }
}
@media (min-width: 960px) {
  .active {
    background-color: #fd2d2a !important;
    color: #fff;
    font-weight: 500;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(17%)
        saturate(7474%) hue-rotate(167deg) brightness(154%) contrast(107%);
    }
  }
  .mobile {
    &-options {
      display: flex;
      li {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        width: 312px;
        text-align: left;
        padding: 10px;
        cursor: pointer;
        margin-right: 14px;
        transition: all ease 0.4s;
      }
    }
  }
}
@media (max-width: 960px) {
  .service {
    padding: 0;
    border: none;
    &-top {
      flex-direction: column;
      border-bottom: 0;
      &__title {
        margin-bottom: 18px;
      }
      &__select {
        width: 100%;
      }
    }
    &-btm {
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      // display: grid;
      // gap: 15px;
      // grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
      &__item {
        width: 48%;
        img {
          height: 196px;
        }
      }
      &__selects {
        flex-direction: column;
        padding: 18px 0;
        border-bottom: 0;
        select {
          margin-right: 0;
          width: 100%;
          margin-bottom: 10px;
        }
        .select {
          width: 100%;
        }
      }
    }
    &-dop {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      margin-top: 18px;
      &__wrap {
        padding-top: 0px;
      }
    }
  }
}
</style>

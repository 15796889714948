<template>
    <div class="tab-items m-none">
      <ul class="tab-list">
        <li
          v-for="(tab, index) in tabItems"
          :key="index"
          class="tab-item"
          
        >
          <router-link
          :to="tab.link"
            class="tab-link tab-title"
            @click="setActive(index)"
          >
            <img :src="tab.image" alt="">
            <p class="gray">{{ tab.title }}</p>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="news">
      <p class="news-title gray">{{ $t("news") }}</p>
      <div class="news-cards" v-if="NEWS">
        <a  v-for="(item, index) in NEWS" :key="index" :href="item.link" target="_blank">
          <div class="news-cards__item">
            <img :src="item.image" alt="">
            <p v-html="item.title"></p>
          </div>
        </a>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  export default {
    data() {
        return {
            activeIndex: 0,
            tabItems: [
            {
                title: this.$t("searchProgram"),
                image: require('@/assets/images/platform/search-program1.svg'),
                id: 1,
                link: "/platform/programs"
            },
            {
                title: this.$t("fav"),
                image: require('@/assets/images/platform/star1.svg'),
                id: 2,
                link: "/platform/favorite"
            },
            {
                title: this.$t("acc"),
                image: require('@/assets/images/platform/profile1.svg'),
                id: 3,
                link: "/platform/cabinet"
            },
            {
                title: this.$t("ourProd"),
                image: require('@/assets/images/platform/tovar1.svg'),
                id: 4,
                link: "/platform/our-products"
            },
            {
                title: this.$t("ourServ"),
                image: require('@/assets/images/platform/service1.svg'),
                id: 5,
                link: "/platform/our-services"
            },
            {
                title: this.$t("use"),
                image: require('@/assets/images/platform/useful1.svg'),
                id: 6,
                link: "/platform/useful"
            }
        ],
        }
    },
    created() {
      this.GET_NEWS();
    },
    methods: {
      ...mapActions(["GET_NEWS"]),
        setActive(index) {
            this.activeIndex = index;
        },
    },
    computed: {
      ...mapGetters(["NEWS"]),
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .one {
    &:hover {
      filter: brightness(0) saturate(100%) invert(0%) sepia(79%) saturate(0%) hue-rotate(148deg) brightness(96%) contrast(100%);
    }
  }
  .gray {
    color: rgba(0, 0, 0, 0.54);
  }
  .news {
    margin-bottom: 40px;
    &-title {
      font-size: 18px;
      line-height: 20px;
      padding: 16px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.10);
    }
    &-cards {
      &__item {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #FFF;
        padding: 8px;
        margin-bottom: 8px;
        width: 300px;
        cursor: pointer;
        p {
          font-size: 18px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.54);
          text-align: center;
          padding-top: 8px;
        }
        img {
          width: 100%;
          height: 114px;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }
  }
  .router-link-exact-active  {
    color: #FFFFFF !important;
    background: #FD2D2A !important;
    transition: 0.4s;
    border-radius: 8px;
    p {
        color: #FFFFFF !important;
    }
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7440%) hue-rotate(128deg) brightness(121%) contrast(99%) !important;
    }
    & path {
      fill: white;
      fill-opacity: 1;
    }
  }
  ul {
    padding-left: 0 !important;
  }
  .tab {
    &-list {
      display: flex;
      flex-direction: column;
      width: 300px;
      overflow: auto;
      transition: 0.4s;
      margin-bottom: 16px;
    }
  
    &-item {
        list-style: none;
        margin-top: 8px;
    }
  
    &-items {
      width: 100%;
    }
  
    &-link {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 8px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 15px;
      }
    }
  }
  </style>
<template>
    <div class="curatorBack" @click="closeModal">
        <div class="curator" v-if="isOk == false" @click.stop>
            <p class="curator__title">Запросить обсуждение</p>
            <p class="curator__sub">Готовы обсудить выбранные университеты с куратором? Выберите время и назначьте встречу!</p>
            <div class="line"></div>
            <div class="curator__dateTime">
                <div class="curator__dateTime__in">
                    <div class="customInput">
                        <label>Выберите дату</label>
                        <select>
                            <option>08.11.2024</option>
                        </select>
                    </div>
                </div>
                <div class="curator__dateTime__in">
                    <div class="customInput">
                        <label>Выберите время</label>
                        <select>
                            <option>12:00</option>
                        </select>
                    </div>
                </div>
            </div>  
            <div class="curator__org">
                <div class="customInput">
                    <label>Выберите организатора</label>
                    <select>
                        <option>Google Meet</option>
                    </select>
                </div>
            </div>
            <p class="curator__text">*При выборе Meet / Zoom, ссылка будет отправлена Вам за 30 минут до начала беседы</p>
            <div class="curator__buttons">
                <button class="curator__buttons__left" @click="isOk = true">Запланировать</button>
                <button class="curator__buttons__right" @click="closeModal">Я передумал(-а)</button>
            </div>
        </div> 
        <div class="curator" v-else>
            <p class="curator__title">Запросить обсуждение</p>
            <p class="curator__sub">Готовы обсудить выбранные университеты с куратором? Выберите время и назначьте встречу!</p>
            <div class="line"></div>
            <img src="@/assets/icons/ok.svg" class="curator__ok"/>
            <button class="curator__ready" @click="closeModal">Готово</button>
        </div> 
    </div>
</template>
<script>
export default {
    data(){
        return{
            isOk: false,
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
}
.curatorBack{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #0000005b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.curator{
    width: 33%;
    background-color: white;
    padding: 40px 60px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    &__title{
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #11415D;
    }
    &__sub{
        color: #0000008A;
        text-align: center;
    }
    &__dateTime{
        width: 100%;
        display: flex;
        justify-content: space-between;
        &__in{
            width: 48%;
        }
    }
    &__org{
        width: 100%;
        margin-top: 10px;
    }
    &__text{
        color: #0000008A;
    }
    &__ready{
        margin-top: 20px;
        background-color: #11415D;
        color: white;
        border: none;
    }
    button{
        width: 48%;
        padding: 15px 0px;
        border-radius: 10px;
    }
    &__buttons{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        &__left{
            background-color: #11415D;
            color: white;
            border: none;
        }
        &__right{
            border: 1px solid #0000001A;
            background-color: inherit;
        }
    }
    &__ok{
        width: 40%;
    }
}
.customInput{
  position: relative;
  display: inline-block;
  width: 100%;
  label{
    position: absolute;
    top: -8px;
    left: 25px;
    background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
    padding: 0 10px;
    font-size: 14px;
    color: #c0c0c0; 
  }
  select {
    width: 100%;
    cursor: pointer !important;
    padding: 0px 25px;
    height: 50px;
    border: 1px solid #e0e0e0; /* Цвет рамки */
    border-radius: 8px;
    font-size: 20px;
    background-color: inherit;
    font-weight: bold;
    color: #000; /* Цвет текста */
    outline: none;
  }
}
</style>
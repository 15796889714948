<template>
    <div class="customCard">
        <div class="customCard__left">
            <swiper 
                :slidesPerView="1" 
                :speed="1000"
                :pagination="{ 
                            clickable: true, 
                }"
                :lazy="true" 
                :loop="true"
            > 
                <swiper-slide v-for="(item) in 4" :key="item" > 
                    <img src="@/assets/icons/test.png"/>
                </swiper-slide> 
            </swiper>
            <div class="bilim">
                <img src="@/assets/icons/bilim.svg"/>
                <p>ВЫБОР СТУДЕНТА</p>
            </div>
        </div>
        <div class="customCard__right">
            <div class="customCard__right__top">
                <p class="customCard__right__top__name">Massachusetts, USA</p>
                <p class="customCard__right__top__spec">Technology and Policy</p>
            </div>
            <div class="line"></div>
            <p class="customCard__right__rate">TOP-30  <span>Education</span></p>
            <div class="customCard__right__buttons" v-if="isFavorite">
                <button class="customCard__right__buttons__red" @click="$router.push('/testPlatformFind/1')">Подробнее</button>
            </div>
            <div class="customCard__right__buttons" v-else>
                <button class="customCard__right__buttons__red" @click="$router.push('/testPlatformFind/1')">Подробнее</button>
                <button class="customCard__right__buttons__fav" v-if="$store.state.role == 'user'">
                    <img src="@/assets/icons/greyHeart.svg"/>
                    <p>В избранное</p>
                </button>
                <button class="customCard__right__buttons__inAdmiss" v-if="$store.state.role == 'curator' && isAdmission">
                    <p>Убрать</p>
                </button>
                <button class="customCard__right__buttons__admiss" v-if="$store.state.role == 'curator'">
                    <img src="@/assets/icons/admission.svg"/>
                    <p>Добавить в поступление</p>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
export default {
    components:{
        Swiper, SwiperSlide,
    },
    props: ['isFavorite', 'isAdmission'],
    data(){
        return{
            isCurator: true
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
    .customCard{
        width: 100%;
        display: flex;
        justify-content: space-between;
            &__left{
                width: 25%;
                position: relative;
                img{
                    width: 100%;
                    border-radius: 10px;
                    aspect-ratio: 1;
                    object-fit: cover;
                }
                .bilim{
                    position: absolute;
                    z-index: 998;
                    background-color: white;
                    top: 10px;
                    left: 10px;
                    display: flex;
                    gap: 5px;
                    color: white;
                    img{
                        width: 13px;
                    }
                    font-size: 12px;
                    padding: 5px;
                    border-radius: 5px;
                    background-color: #FD2D2A;
                    align-items: center;
                }
            }
            &__right{
                width: 73%;
                display: flex;
                flex-direction: column;
                gap: 15px;
                &__top{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    &__name{
                        color: #0000008A;
                    }
                    &__spec{
                        font-size: 24px;
                        font-weight: 700;
                    }
                }
                &__rate{
                    font-weight: 700;
                    color: #FD2D2A;
                    span{
                        color: #11415D;
                        margin-left: 5px;
                    }
                }
                &__buttons{
                    display: flex;
                    gap: 20px;
                    align-items: stretch;
                    button{
                        padding: 10px;
                        border-radius: 5px;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        img{
                            width: 15px;
                        }
                    }
                    &__red{
                        background-color: #FD2D2A;
                        color: white;
                        width: 35%;
                        border: none;
                    }
                    &__inAdmiss{
                        width: 20%;
                        border: 1px solid #FD2D2A !important;
                        background-color: white;
                        color: #FD2D2A;
                        border: none;
                    }
                    &__admiss{
                        width: 55%;
                        border: 1px solid #0000001A !important;
                        background-color: white;
                        color: #0000008A;
                        border: none;
                    }
                    &__fav{
                        width: 45%;
                        background-color: transparent;
                        border: 1px solid #00000010;
                        border-radius: 10px;
                    }
                }
            }
    }
</style>
<template>
    <div class="tab-items m-none">
      <ul class="tab-list">
        <li
          v-for="tab in tabItems"
          :key="tab.id"
          class="tab-item"
          :class="{ activeItem: tab === selected }"
        >
          <div
            :class="{ active: tab === selected }"
            class="tab-link tab-title"
            @click="setTab(tab)"
          >
            {{ tab }}
          </div>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      tabItems: {
        type: Array,
        required: true,
      },
      selected: {
        type: String,
        required: true,
      },
    },
    methods: {
      setTab(tab) {
        this.$emit("setTab", tab);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .active {
    color: #FFFFFF !important;
    background: #11415D !important;
    transition: 0.4s;
  }
  ul {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
  }
  .tab {
    &-list {
      display: flex;
      justify-content: space-between;
    //   width: 650px;
    }
    &-item {
        list-style: none;
    }
    &-items {
      width: 725px;
      padding: 8px;
      background: #F5F7FB;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      margin: 28px 0;
    }
  
    &-link {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      cursor: pointer;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.10);
      padding: 11px;
      width: 230px;
      border-radius: 8px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 15px;
      }
    }
  }
  @media (min-width: 960px) and (max-width: 1440px) {
    .tab {
      &-items {
        width: 100%;
      }
      &-item {
        width: 100%;
      }
      &-link {
        width: 98%;
      }
    }
  }
  </style>
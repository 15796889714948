<template>
    <progressBar />
    <extend v-if="openExtend" @closeModal="openExtend = false"/>
    <ReviewModal v-if="openReview" @closeModal="openReview = false"/>
    <div class="home">
        <div class="home__left">
            <platformTable />
        </div>
        <div class="home__right">
            <div class="home__right__top">
                <div class="home__right__top__tabs">    
                    <swiper 
                    class="home__right__top__tabs__swiper"
                    @swiper="getSwiperCategory" 
                    :breakpoints="{
                        0: {
                            slidesPerView: 'auto'
                        }
                    }"
                    :spaceBetween="50"
                    :speed="500"
                    :lazy="true" 
                > 
                    <swiper-slide v-for="(tab, index) in tabs" 
                        :key="tab"
                        :class="{ customTab: selectedTab === index }"
                        @click="selectTab(index)"
                        ref="tabs"> 
                        {{ tab.name }}
                    </swiper-slide> 
                </swiper>
                </div>
                <div class="home__right__top__right">
                    <userInfo />
                </div>
            </div>
            <div class="line"></div>
            <div class="main" v-if="selectedTab == 0">
                <div class="main__top">
                    <div class="main__top__left">
                        <div class="status">
                            <div class="customTitle">
                                <p>Статус договора</p>
                                <div class="line"></div>
                            </div>
                            <div class="status__block">
                                <div class="status__block__left">
                                    <p>0 дней</p>
                                </div>
                                <div class="status__block__right">
                                    <p class="status__block__right__title">Истек</p>
                                    <p class="status__block__right__sub">№ 4256 от 06.11.2024</p>
                                    <button @click="openExtend = true">Продлить</button>
                                </div>
                            </div>
                        </div>
                        <div class="deadlines">
                            <div class="customTitle">
                                <p>Ключевые сроки</p>
                                <div class="line"></div>
                            </div>
                            <div class="deadlines__block">
                            <div class="deadlines__block__in" v-for="(item, index) in deadlines" :key="index">
                                <p class="deadlines__block__in__title">{{ item.name }}</p>
                                <div class="deadlines__block__in__new">
                                <div class="deadlines__block__in__new__in" v-for="(newItem, newIndex) in item.in" :key="newIndex">
                                    <div class="deadlines__block__in__new__in__left">
                                        <div class="deadlines__block__in__new__in__left__mini">
                                            <div class="deadlines__block__in__new__in__left__mini__in" :class="{backGreen: newItem.status == 'green', backRed: newItem.status == 'red'}">
                                                
                                            </div>  
                                        </div>  
                                        <p :class="{textGreen: newItem.status == 'green', textRed: newItem.status == 'red'}">{{ newItem.name }}</p>
                                    </div>  
                                    <div class="deadlines__block__in__new__in__right">
                                        <p :class="{textRed: newItem.status == 'red'}"> /{{ newItem.date }} </p>
                                    </div>
                                </div> 
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="main__top__center">
                        <div class="customTitle">
                            <p>Утвержденные программы</p>
                            <div class="line"></div>
                        </div>
                        <div class="main__top__center__block">
                            <div class="main__top__center__block__in" v-for="item in 4" :key="item">
                                <div class="main__top__center__block__in__left">
                                </div>
                            <div class="main__top__center__block__in__right">
                                <p class="main__top__center__block__in__right__title">FIRST CHOICE</p>
                                <p class="main__top__center__block__in__right__sub">Technology and Policy</p>
                                <p class="main__top__center__block__in__right__deadline">Дэдлайн 1: 22.12.2024</p>
                                <p class="main__top__center__block__in__right__deadline">Дэдлайн 2: 02.12.2025</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="main__top__right">
                        <div class="grant">
                            <div class="customTitle">
                                <p>Международная стипендия</p>
                                <div class="line">
                                </div>
                            </div>
                            <div class="grant__block">
                                <div class="grant__block__in" v-for="item in 2" :key="item">
                                    <p class="grant__block__in__name">Прием документов</p>
                                    <p class="grant__block__in__date">05.04-10.05.2025</p>
                                </div>
                            </div>
                        </div>
                        <div class="risk">
                            <div class="customTitle">
                                <p>Риски</p>
                                <div class="line">
                                </div>
                            </div>
                            <div class="risk__block">
                                <ul class="risk__block__in" v-for="item in 5" :key="item">
                                    <li>Низкий балл по IELTS</li>
                                </ul>  
                            </div>
                        </div>
                        <div class="contact">
                            <div class="customTitle">
                                <p>КОНТАКТЫ И ПОМОЩЬ</p>
                                <div class="line">
                                </div>
                            </div>
                            <div class="contact__block">
                                <swiper 
                                    :slidesPerView="4" 
                                    :spaceBetween="20"
                                    :navigation="{
                                        nextEl: '.arrowRight',
                                        prevEl: '.arrowLeft'
                                    }"
                                    :autoplay="{
                                        delay: 3000,
                                    }"      
                                    :speed="1000"
                                    :lazy="true" 
                                > 
                                    <swiper-slide v-for="(item) in 4" :key="item"> 
                                        <img src="@/assets/icons/tg.svg"/>
                                        <p>Куратор</p>
                                    </swiper-slide> 
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main__bot">
                    <div class="plan">
                        <div class="customTitle">
                            <p>ИНДИВИДУАЛЬНЫЙ ПЛАН</p>
                            <div class="line"></div>
                        </div>
                        <div class="plan__block">
                            <div class="plan__block__in" v-for="item in 2" :key="item">
                                <div class="plan__block__in__top">  
                                    <img src="@/assets/icons/file.svg"/>
                                </div>
                                <p>Наставничество</p>
                            </div>
                        </div>
                    </div>
                    <div class="final" @click="selectTab(3)">
                        <div class="customTitle">
                            <p>ФИНАЛЬНЫЕ ДОКУМЕНТЫ</p>
                            <div class="line"></div>
                        </div>
                        <div class="final__block">
                            <div class="final__block__in" v-for="item in final" :key="item">
                                <div class="final__block__in__top" :class="{customBorder: item.status == 'green'}"> 
                                    <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                                </div>
                                <p :class="{textGreen: item.status == 'green'}">{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="general" v-if="selectedTab == 1">
                <div class="general__top">
                    <p class="general__top__title">Ваши покупки</p>
                    <div class="general__top__block">
                        <div class="general__top__block__in" v-for="item in 2">
                            <div class="customTitle">
                                <p>НАСТАВН.</p>
                                <div class="line"></div>
                            </div>
                            <img src="@/assets/icons/bolashakIcon.svg"/>
                            <p class="general__top__block__in__name">BOLASHAK</p>
                            <p class="general__top__block__in__sub">3 ВУЗа</p>
                        </div>
                    </div>
                </div>
                <div class="general__bot">
                    <p class="general__bot__title">Личная информация</p>
                    <div class="general__bot__inputs">
                        <div class="customInput">
                            <label for="surname">Фамилия</label>
                            <input type="text" id="surname" value="Жексебайулы">
                        </div>
                        <div class="customInput">
                            <label for="name">Имя</label>
                            <input type="text" id="surname" value="Руслан">
                        </div>
                        <div class="customInput">
                            <label for="iin">ИИН</label>
                            <input type="text" id="iin" value="4587675456759">
                            <img src="@/assets/icons/galochka.svg"/>
                        </div>
                        <div class="customInput">
                            <label for="sex">Пол</label>
                            <input type="text" id="sex" value="Мужской">
                        </div>
                        <div class="customInput">
                            <label for="date">Дата рождения</label>
                            <input type="date" id="date" value="1990.04.24">
                        </div>
                        <div class="customInput">
                            <label for="country">Страна</label>
                            <select id="country">
                                <option>Казахстан</option>
                            </select>
                        </div>
                        <div class="customInput">
                            <label for="city">Город</label>
                            <select id="city">
                                <option>Алматы</option>
                            </select>
                        </div>
                        <div class="customInput">
                            <label for="address">Адрес</label>
                            <input type="text" id="address" value="пр. Абай, ...">
                        </div>
                        <div class="customInput">
                            <label for="phone">Телефон</label>
                            <input type="text" id="phone" value="+7707655588">
                            <img src="@/assets/icons/galochka.svg"/>
                        </div>
                        <div class="customInput">
                            <label for="mail">Почта</label>
                            <input type="text" id="mail" value="userID1@gmail.com">
                            <img src="@/assets/icons/galochka.svg"/>
                        </div>
                        <div class="customInput">
                            <label for="tg">Telegram</label>
                            <input type="text" id="tg" value="userID1">
                        </div>
                        <div class="customInput">
                            <label for="iduser">ID пользователя</label>
                            <input type="text" id="iduser" value="4587675">
                        </div>
                    </div>
                    <div class="general__bot__buttons">
                        <button class="general__bot__buttons__save">Применить</button>
                        <button class="general__bot__buttons__delete">Сбросить</button>
                    </div>
                </div>
            </div>
            <div class="result" v-if="selectedTab == 2">
                <p class="result__title">Академические данные - БАКАЛАВР</p>
                <div class="result__inputs">
                    <div class="customInput">
                        <label for="school">Школа</label>
                        <input type="text" id="school" placeholder="Введите название школы">
                    </div>
                    <div class="customInput">
                        <label for="studyField">Углубленное изучение</label>
                        <select id="studyField">
                            <option value="fizmat">Физмат</option>
                            <option value="humanities">Гуманитарий</option>
                        </select>
                    </div>
                    <div class="customInput">
                        <label for="averageScore">Средний балл аттестата</label>
                        <input type="text" id="averageScore" placeholder="Введите средний балл">
                    </div>
                    <div class="customInput">
                        <label for="placementLevel">Placement Test Level</label>
                        <input type="text" id="placementLevel" placeholder="Введите уровень теста">
                    </div>
                    <div class="customInput">
                        <label for="placementDate">Placement Test Date</label>
                        <input type="date" id="placementDate">
                    </div>
                    <div class="customInput">
                        <label for="ielts">IELTS/TOEFL</label>
                        <input type="text" id="ielts" placeholder="Введите результат IELTS/TOEFL">
                    </div>
                    <div class="customInput">
                        <label for="ieltsDate">IELTS/TOEFL Date</label>
                        <input type="date" id="ieltsDate">
                    </div>
                    <div class="customInput">
                        <label for="sat">SAT</label>
                        <input type="text" id="sat" placeholder="Введите результат SAT">
                    </div>
                </div>
                <p class="result__title">Академические данные - МАГИСТРАТУРА</p>
                <div class="result__inputs">
                    <div class="customInput">
                        <label for="bachelorGPA">GPA Бакалавриат</label>
                        <input type="text" id="bachelorGPA" placeholder="Введите GPA бакалавриата">
                    </div>
                    <div class="customInput">
                        <label for="masterGPA">GPA Магистратура</label>
                        <input type="text" id="masterGPA" placeholder="Введите GPA магистратуры">
                    </div>
                    <div class="customInput">
                        <label for="placementMasterLevel">Placement Test Level</label>
                        <input type="text" id="placementMasterLevel" placeholder="Введите уровень теста">
                    </div>
                    <div class="customInput">
                        <label for="placementMasterDate">Placement Test Date</label>
                        <input type="date" id="placementMasterDate">
                    </div>
                    <div class="customInput">
                        <label for="kazTest">KazTest/Qazresmi</label>
                        <input type="text" id="kazTest" placeholder="Введите результат KazTest/Qazresmi">
                    </div>
                    <div class="customInput">
                        <label for="kazTestDate">KazTest/Qazresmi Date</label>
                        <input type="date" id="kazTestDate">
                    </div>
                    <div class="customInput">
                        <label for="gre">GRE/GMAT</label>
                        <input type="text" id="gre" placeholder="Введите результат GRE/GMAT">
                    </div>
                    <div class="customInput">
                        <label for="greDate">GRE/GMAT Date</label>
                        <input type="date" id="greDate">
                    </div>
                </div>
                <p class="result__title">Академические данные - ДОКТОРАНТУРА</p>
                <div class="result__inputs">
                    <div class="customInput">
                        <label for="doctorBachelorGPA">GPA Бакалавриат</label>
                        <input type="text" id="doctorBachelorGPA" placeholder="Введите GPA бакалавриата">
                    </div>
                    <div class="customInput">
                        <label for="doctorMasterGPA">GPA Магистратура</label>
                        <input type="text" id="doctorMasterGPA" placeholder="Введите GPA магистратуры">
                    </div>
                    <div class="customInput">
                        <label for="doctorPlacementLevel">Placement Test Level</label>
                        <input type="text" id="doctorPlacementLevel" placeholder="Введите уровень теста">
                    </div>
                    <div class="customInput">
                        <label for="doctorPlacementDate">Placement Test Date</label>
                        <input type="date" id="doctorPlacementDate">
                    </div>
                    <div class="customInput">
                        <label for="doctorKazTest">KazTest/Qazresmi</label>
                        <input type="text" id="doctorKazTest" placeholder="Введите результат KazTest/Qazresmi">
                    </div>
                    <div class="customInput">
                        <label for="doctorKazTestDate">KazTest/Qazresmi Date</label>
                        <input type="date" id="doctorKazTestDate">
                    </div>
                    <div class="customInput">
                        <label for="doctorGRE">GRE/GMAT</label>
                        <input type="text" id="doctorGRE" placeholder="Введите результат GRE/GMAT">
                    </div>
                    <div class="customInput">
                        <label for="doctorGREDate">GRE/GMAT Date</label>
                        <input type="date" id="doctorGREDate">
                    </div>
                </div>
                <p class="result__title" style="margin-top: 40px;">Дополнительные данные</p>
                <div class="result__info">
                    <img src="@/assets/icons/info.svg"/>
                    <p>Здесь будут отображаться данные добавленные Вашим куратором</p>
                </div>
                <div class="result__buttons">
                    <button class="result__buttons__save">Применить</button>
                    <button class="result__buttons__delete">Сбросить</button>
                </div>
            </div>
            <div class="document" v-if="selectedTab == 3">
                <p class="document__title">Файлы и документы</p>
                <div class="document__block">
                    <div class="document__block__in" v-for="item in documents" :key="item">
                        <div class="document__block__in__left">
                            <img :src="require('@/assets/icons/' + item.img + '.svg')"/>
                        </div>
                        <div class="line2"></div>
                        <div class="document__block__in__right">
                            <p class="document__block__in__right__pdf" :class="{approved: item.type == 'approved', notApproved: item.type == 'not'}">Link-here</p>
                            <p class="document__block__in__right__name" :class="{approved: item.type == 'approved', notApproved: item.type == 'not'}">{{ item.name }}</p>
                            <div class="document__block__in__right__undefined" v-if="item.type == 'undefined'">
                                <p class="document__block__in__right__undefined__left" @click="openReview = true">Approve</p>
                                <div class="line2"></div>
                                <p class="document__block__in__right__undefined__right">Not yet</p>
                            </div>
                            <div class="document__block__in__right__approved" v-if="item.type == 'approved'">Approved</div>
                            <div class="document__block__in__right__not" v-if="item.type == 'not'">Not yet</div>
                        </div>
                    </div>
                </div>
                <p class="document__title">Дополнительные документы</p>
                <div class="document__info">
                    <img src="@/assets/icons/info.svg"/>
                    <p>Здесь будут отображаться данные добавленные Вашим куратором</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import platformTable from '@/components/platformTable.vue';
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import userInfo from '@/components/userInfo.vue';
import { Navigation } from "swiper";
import extend from '@/components/newPlatform/extend.vue';
import progressBar from '@/components/newPlatform/progressBar.vue';
import ReviewModal from '@/components/newPlatform/reviewModal.vue';
export default {
  components: { 
    Swiper,
    SwiperSlide,
    platformTable,
    userInfo,
    extend,
    progressBar,
    ReviewModal
},
  data(){
    return{
        selectedTab: 0,
        openExtend: false,
        swiperRefCategory: null,
        swiperRef: null,
        activeSwiperIndex: 0,
        openReview: false,
        documents: [
            {
                img: 'doc1',
                name: 'Утвержден. программы',
                type: 'undefined'
            },
            {
                img: 'doc2',
                name: 'Резюме',
                type: 'approved'
            },
            {
                img: 'doc3',
                name: 'Реком. письмо 1',
                type: 'not'
            },
            {
                img: 'doc4',
                name: 'Утвержден. программы',
                type: 'undefined'
            },
        ],
        final: [
            {
                img: 'galochka',
                name: 'Утвержден. программы',
                status: 'green'
            },
            {
                img: 'galochka',
                name: 'Утвержден. программы',
                status: 'green'
            },
            {
                img: 'galochka',
                name: 'Утвержден. программы',
                status: 'green'
            },
            {
                img: 'mail',
                name: 'Реком. письмо 2'
            },
            {
                img: 'file',
                name: 'Эссе',
            },
            {
                img: 'file',
                name: 'Эссе 2',
            },
            {
                img: 'dots',
                name: 'Дополн.',
            },
        ],
        deadlines: [
            {
                name: 'General English',
                in: [
                    {
                        name: 'ELEMENTARY',
                        date: '15 Декабря',
                        status: 'green'
                    },
                    {
                        name: 'PRE-INTERMEDIATE',
                        date: '16 Декабря',
                        status: 'green'
                    },
                    {
                        name: 'PRE-INTERMEDIATE',
                        date: '21 Декабря',
                        status: 'red'
                    },
                    {
                        name: 'ELEMENTARY',
                        date: '15 Декабря',
                    },
                ]
            },
            {
                name: 'Тесты',
                in: [
                    {
                        name: 'IELTS',
                        date: '15 Декабря',
                        status: 'red'
                    },
                    {
                        name: 'GRE / GMAT',
                        date: '15 Декабря',
                    },
                    {
                        name: 'KazTEST',
                        date: '21 Декабря',
                    },
                    {
                        name: 'Other',
                        date: '22 Декабря',
                    },
                ]
            }
        ],
        tabs: [
            {
                name: 'Главная страница'
            },
            {
                name: 'Общее'
            },
            {
                name: 'Результаты'
            },
            {
                name: 'Документы'
            }
        ],
        breakpoints: {
        0: {
            slidesPerView: 3.4,
            spaceBetween: 20,
        },
        },
    }
  },
  methods: {
        selectTab(index) {
            this.selectedTab = index;
            this.swiperRefCategory?.slideTo(index, 500);
            this.scrollToTop();
        },
        getSwiperCategory(swiper) {
            this.swiperRefCategory = swiper;
            this.activeSwiperIndex = swiper.activeIndex;
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
    },
}
</script>
<style lang="scss" scoped>

.customBorder{
    border: 1px solid #14D448 !important; 
}
.backGreen{
    background-color: #00D928;
}
.backRed{
    background-color: #FD2D2A;
}
.textGreen{
    color: #00D928;
}
.textRed{
    color: #FD2D2A;
}
.main{
    display: flex;
    flex-direction: column;
    gap: 25px;
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        &__center{
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: 15px 30px 25px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            border-radius: 10px;
            &__block{
                display: flex;
                flex-direction: column;
                gap: 25px;
                &__in{
                    display: flex;
                    justify-content: space-between;
                    &__left{
                        width: 40%;
                        box-shadow: 0px 0px 8px 4px #0000004D inset;
                        border-radius: 5px;
                        aspect-ratio: 1;
                        background-image: url('/src/assets/icons/test.png');
                        background-position: center;
                        background-size: cover;
                    }
                    &__right{
                        width: 55%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        &__title{
                            color: #FD2D2A;
                        }
                        &__sub{
                            font-size: 24px;
                            font-weight: 600;
                        }
                        &__deadline{
                            color: #0000008A;
                        }
                    }
                }
            }
        }
        &__left, &__center, &__right{
            width: 32%;
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
        .status{
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: 15px 30px 25px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            border-radius: 10px;
            &__block{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__left{
                    width: 30%;
                    aspect-ratio: 1;
                    border: 12px solid #FD2D2A;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #0000008A;
                }
                &__right{
                    width: 60%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    &__title{
                        font-size: 36px;
                        font-weight: 700;
                        color: #FD2D2A;
                    }
                    &__sub{
                        color: #0000008A;
                    }
                    button{
                        width: 80%;
                        border: none;
                        background-color: #FF0000;
                        padding: 12px 0px;
                        color: white;
                        border-radius: 5px;
                    }
                }
            }
        }
        .deadlines{
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: 15px 30px 25px;
            display: flex;
            flex-direction: column;
            // background-image: url('@/assets/icons/keyBack.png');
            // background-position: cover;
            gap: 25px;
            border-radius: 10px;
            height: 100%;
            &__block{
                display: flex;
                flex-direction: column;
                gap: 25px;
                &__in{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__title{
                        font-size: 24px;
                        font-weight: 700;
                    }
                    &__new{
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        &__in{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            &__left{
                                display: flex;
                                gap: 15px;
                                &__mini{
                                    width: 22px;
                                    height: 22px;
                                    border: 1px solid #0000001A;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    &__in{
                                        height: 16px;
                                        width: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .grant, .risk, .contact{
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: 15px 30px 25px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            border-radius: 10px;
        }
        .grant{
            &__block{
                display: flex;
                justify-content: space-between;
                &__in{
                    width: 48%;
                    padding: 15px 10px 40px;
                    border: 1px solid #0000001A;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;
                    &__name{
                        color:#0000008A;
                    }
                    &__date{
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
        }
        .risk{
            color: white;
            width: 100%;
            background-image: url('@/assets/icons/risk.png');
            background-size: cover;
            position: relative;
            p{
                color: white;
                z-index: 2;
            }
            &__block{
                z-index: 2;
                &__in{
                    &:nth-child(1),&:nth-child(2){
                        font-weight: 700;
                    }
                }
            }
            &::before{
                content: '';
                border-radius: 10px;
                background: linear-gradient(270deg, rgba(255, 0, 0, 0) 0%, #FF0000 100%);
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
            }
        }
        .contact{
            padding-bottom: 30px;
            height: 100%;
            &__block{
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 13px;
                .swiper{
                    width: 100%;
                    &-slide{
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        align-items: center;
                        cursor: pointer;
                        img{
                            width: 75%
                        }
                    }
                }
            }
        }
    }
    &__bot{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .plan, .final{
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            padding: 15px 30px 25px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            border-radius: 10px;
        }
        .plan{
            width: 32%;
            &__block{
                display: flex;
                justify-content: space-between;
                &__in{
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    align-items: center;
                    color: #0000008A;
                    &__top{
                        width: 100%;
                        aspect-ratio: 1.2;
                        border: 1px solid #0000001A;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        img{
                            width: 45px;
                        }
                    }
                }
            }
        }
        .final{
            width: 66%;
            &__block{
                display: flex;
                justify-content: space-between;
                &__in{
                    width: 12%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                    gap: 20px;
                    img{
                        width: 40px;
                    }
                    &__top{
                        width: 100%;
                        aspect-ratio: 0.8;
                        border: 1px solid #0000001A;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
.customTitle{
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    gap: 10px;
    align-items: center;
    text-align: center;
    font-size: 18px;
    color: #0000008A;
    width: 100%;
}
.line1{
    border: 1px solid #0000001A;
}
.line{
    border-top: 1px solid #0000001A;
    width: 100%;    
}
.line2{
    border-left: 1px solid #0000001A;
}
.customTab{
    color: #000000 !important;
}
.home{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #11415D 0px, #2488C3 500px);
    padding-top: 25px;
    min-height: 100vh;
    &__left{
        width: 20%;
    }
    &__right{
        width: 80%;
        background-color: #F5F7FB;
        padding: 60px 80px;
        border-top-left-radius: 25px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__tabs {
                width: 70%;
                font-weight: 600;
                font-size: 36px;
                color: #00000026;
                display: flex;
                position: relative;
                &__swiper{
                    width: 100%;
                    .swiper-slide {
                        cursor: pointer;
                        width: fit-content;
                    }
                }
                .customTab {
                    color: #000; // Активный таб выделяется
                }
            }
            &__right{
                width: 25%;
            }
        }
    }
}
.general{
    display: flex;
    flex-direction: column;
    gap: 25px;
    &__top,&__bot{
        &__title{
            font-size: 24px;
            font-weight: 700;
        }
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    &__top{
        &__block{
            display: flex;
            gap: 2%;
            &__in{
                width: 23.5%;
                box-shadow: 0px 4px 3px 1px #0000000D;
                background: #FFFFFF;
                padding: 15px 30px 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                border-radius: 10px;
                font-weight: 700;
                img{
                    width: 60px;
                    aspect-ratio: 1;
                }
                &__name{
                    font-size: 24px;
                }
                &__sub{
                    color: #FD2D2A;
                }
            }
        }
    }
    &__bot{
        &__inputs{
            display: flex;
            flex-wrap: wrap;
            gap: 2%;
            row-gap: 25px;
        }
        &__buttons{
            display: flex;
            gap: 2%;
            button{
                width: 23.5%;
                border: none;
                padding: 15px 0px;
                border-radius: 10px;
            }
            &__save{
                color: white;
                font-size: 20px;
                background-color: #11415D;
                border: 1px solid #11415D;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #2076a7;
                }
            }
            &__delete{
                background-color: inherit;
                border: 1px solid #0000001A !important;
                color: #0000008A;
                font-size: 20px;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
            }
        }
    }
}
.result{
    display: flex;
    flex-direction: column;
    gap: 25px;
    &__title{
        font-size: 24px;
        font-weight: 700;
    }
    &__inputs{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: 20px;
    }
    &__info{
        display: flex;
        gap: 20px;
        align-items: center;
        img{
            width: 20px;
            height: 20px;
        }
    }
    &__buttons{
        display: flex;
        gap: 2%;
        button{
            width: 23.5%;
            border: none;
            padding: 15px 0px;
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: 20px;
            background-color: #11415D;
            transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #2076a7;
                }
        }
        &__delete{
            background-color: inherit;
            font-size: 20px;
            border: 1px solid #0000001A !important;
            color: #0000008A;
                transition: all 0.5s ease;
                &:hover{
                    color: white;
                    background-color: #11415D;
                    border: 1px solid #11415D;
                }
        }
    }
}
.customInput{
  position: relative;
  display: inline-block;
  width: 23.5%;
  cursor: pointer;
  label{
    position: absolute;
    top: -8px;
    left: 25px;
    background-color: #F5F7FB; /* Цвет фона, чтобы совпадал с фоном */
    padding: 0 10px;
    font-size: 14px;
    color: #c0c0c0; 
  }
  input, select {
    width: 100%;
    padding: 0px 25px;
    height: 50px;
    border: 1px solid #e0e0e0; /* Цвет рамки */
    border-radius: 8px;
    font-size: 20px;
    background-color: inherit;
    color: #000; /* Цвет текста */
    outline: none;
  }
  img{
    position: absolute;
    left: 15px;
    top: 50%;
    width: 5%;
    height: 10px;
    transform: translate(-50%, -50%);
    filter: brightness(0) saturate(100%) invert(46%) sepia(75%) saturate(3165%) hue-rotate(213deg) brightness(100%) contrast(103%);
  }
}
.approved{
    color: #14D448;
}
.notApproved{
    color: #FF9500;
}
.document{
    display: flex;
    flex-direction: column;
    gap: 25px;
    &__title{
        font-size: 24px;
        font-weight: 700;
        margin-top: 40px;
    }
    &__block{
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: 25px;
        &__in{
            width: 23.5%;
            padding: 15px 25px;
            display: flex;
            justify-content: space-between;
            box-shadow: 0px 4px 3px 1px #0000000D;
            background: #FFFFFF;
            border-radius: 10px;
            &__left{
                width: 15%;
                img{
                    width: 100%;
                }
            }
            &__right{
                width: 75%;
                display: flex;
                flex-direction: column;
                gap: 5px;
                &__name{
                    font-weight: 700;
                    flex: 1;
                }
                &__undefined{
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 20px; 
                    border: 1px solid #0000001A;
                    border-radius: 10px;
                    cursor: pointer;
                    &__left{
                        color: #00D928;
                    }
                    &__right{
                        color: #FF9500;
                    }
                }
                &__approved{
                    padding: 10px 20px;
                    background-color: #F8F8F8;
                    width: fit-content;
                    border-radius: 10px;
                    color: #0000008A;
                    cursor: pointer;
                }
                &__not{
                    padding: 10px 20px;
                    background-color: #FF9500;
                    width: fit-content;
                    color: white;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
        }
        &__plus{
            width: 23.5%;
            padding: 30px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 8px 4px #0000000D inset;
            border: 1px solid #0000001A;
            border-radius: 10px;
            cursor: pointer;
            img{
                width: 60px;
                height: 60px;
            }
        }
    }
    &__info{
        display: flex;
        gap: 20px;
        align-items: center;
        img{
            width: 20px;
            height: 20px;
        }
    }
}
</style>
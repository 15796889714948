<template>
  <div class="program" v-if="MAIN_PAGE">
    <div class="container">
      <div class="program__content">
        <div class="program__main" v-if="MAIN_PAGE.homeHistory">
          <h3 class="program__main-title">{{ this.$t("ourRes") }}</h3>
          <h1
            class="program__main-title2 w36-700"
            v-html="MAIN_PAGE.homeHistory.title"
          ></h1>
          <p
            class="program__main-text w18-600"
            v-html="MAIN_PAGE.homeHistory.description"
          ></p>
        </div>
        <div class="program__slide m_none" v-if="MAIN_PAGE.homeHistoryItems">
            <div class="program-zoom__swiper" v-show="isShow">
              <div v-if="isShow" class="program-zoom__close" @click="isShow = false">
                <img src="@/assets/images/close-white.png" alt="">
              </div>
              <swiper
                v-show="isShow"
                class="swiper0"
                ref="swiper"
                :thumbs="{ swiper: thumbsSwiper }"
                :modules="modules"
                :navigation="navigation22"
                :slides-per-group="1"
                :loop="true"
                :speed="400">
                    <swiper-slide v-for="item in MAIN_PAGE.homeHistoryItems" :key="item" class="info__image-main">
                          <div class="program-zoom">
                            <img class="program-zoom__img" :src="item.image" v-if="item?.image"/>
                          </div>
                    </swiper-slide>
                </swiper>
                <div class="nav2 m_none">
                  <div class="swiper-button-prev swiperButtonPrev4">
                    <img src="@/assets/images/mainPage/linia-left.png" alt="" />
                  </div>
                  <div class="swiper-button-next swiperButtonNext4">
                    <img src="@/assets/images/mainPage/linia-right.png" alt="" />
                  </div>
                </div>
            </div>
            <div class="swiper1">
              <swiper 
                ref="swiper1"
                :speed="550"
                :space-between="20"
                :breakpoints="breakpoints1"
                :slides-per-group="1"
                :loop="true"
                :modules="modules"
                :navigation="navigation21"
                @swiper="setThumbsSwiper"
                @slideChange="setThumbsSwiper">
                  <swiper-slide v-for="item in MAIN_PAGE.homeHistoryItems" :key="item" class="" @click="isShow = true">
                    <div class="program__slider__card-image">
                      <img class="program__slider__card-img" :src="item.image"  v-if="item?.image"/>
                    </div>
                  </swiper-slide>
              </swiper>
              <div class="nav m_none">
                <div class="swiper-button-prev swiperButtonPrev3">
                  <img src="@/assets/images/mainPage/linia-left.png" alt="" />
                </div>
                <div class="swiper-button-next swiperButtonNext3">
                  <img src="@/assets/images/mainPage/linia-right.png" alt="" />
                </div>
              </div>
            </div>
        </div>
        <div class="mobile-swiper d_none">
            <swiper
              class="swiper0"
              ref="swiper"
              :navigation="navigation23"
              :slides-per-group="1"
              :slides-per-view="1"
              :loop="true"
              :modules="modules"
              :speed="400">
              <swiper-slide v-for="item in MAIN_PAGE.homeHistoryItems" :key="item" class="info__image-main" v-viewer>
                <div class="program-zoom">
                  <img class="program-zoom__img" :src="item.image" v-if="item?.image"/>
                </div>
              </swiper-slide>
            </swiper>
            <div class="nav2">
              <div class="swiper-button-prev swiperButtonPrev5">
                <img src="@/assets/images/mainPage/linia-left.png" alt="" />
              </div>
              <div class="swiper-button-next swiperButtonNext5">
                <img src="@/assets/images/mainPage/linia-right.png" alt="" />
              </div>
            </div>
        </div>
      </div>
      <div class="program__pageBreak m_none">
        <img src="@/assets/images/mainPage/pageBreak.png" alt="" />
      </div>
      <span class="program__mobLine"></span>
    </div>
  </div>
  <!-- <div v-if="isShow" @click="isShow = false" class="visible"></div> -->
</template>
<script>
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs } from "swiper";
import "swiper/css";
import 'viewerjs/dist/viewer.css';
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
// import VLazyImage from "v-lazy-image";
SwiperCore.use([Navigation, Thumbs]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["MAIN_PAGE"],
  data: () => ({
    isShow: false,
    thumbsSwiper: null,
    navigation21: {
      nextEl: ".swiperButtonNext3",
      prevEl: ".swiperButtonPrev3",
    },
    navigation22: {
      nextEl: ".swiperButtonNext4",
      prevEl: ".swiperButtonPrev4",
    },
    navigation23: {
      nextEl: ".swiperButtonNext5",
      prevEl: ".swiperButtonPrev5",
    },
    zoomImg: null,
    breakpoints1: {
      1300: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
      990: {
        slidesPerView: 3,
        slidesPerGroup: 1
      },
      760: {
        slidesPerView: 2,
        slidesPerGroup: 1
      },
      460: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
  }),
  methods: {
    selectedImage(item) {
      this.zoomImg = item.image;
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  }
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.info {
    display: flex;
    flex-direction: column;
    &__image {
        &-main {
            overflow: hidden;
            height: 90vh;
            z-index: 111;
            img {
              width: 600px;
              object-fit: contain;
            }
        }
        &__swiper {
            margin-top: 20px;
            &__image {
                height: 110px;
                padding: 5px 0;
                width: 110px;
                // width: 80px;
                overflow: hidden;
                border-radius: 10px;
                background: white;
                cursor: pointer;
                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
            .swiper-slide {
            cursor: pointer;
                &-thumb-active {
                    border: 1px solid #43C667 !important;
                }
            }
        }
    }
}
.container {
  position: relative;
}
.visible {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;
  background: rgba(0, 0, 0, 0.481);
  opacity: 0.8;
}
.swiper1, .swiper0 {
  position: relative;
}
.nav {
  position: absolute;
  width: 100%;
  top: 50%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}
.nav2 {
  position: absolute;
  width: 74%;
  top: 32%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  .swiper {
    &-button {
      &-prev {
        left: 34%;
      }
    }
  }
}
.swiper-button-next {
  right: -35px;
  &::after {
    opacity: 0;
  }
  img {
    width: 35px;
    height: 35px;
  }
}
.swiper-button-prev {
  left: -30px;
  &::after {
    opacity: 0;
  }
  img {
    width: 35px;
    height: 35px;
  }
}
.swiper0 {
    margin-bottom: 30px;
  }
.program {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.351) 0%,
    rgba(0, 0, 0, 0) 0%
  );
  &__content {
    padding: 80px 0px;
  }
  &__slide {
    position: relative;
  }
  &__main {
    align-items: center;
    margin-bottom: 80px;
    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #fd2d2a;
      @media screen and (max-width: $mobile) {
        padding-top: 18px;
      }
    }
    &-title2 {
      text-align: center;
      padding: 4px 18px;
    }
    &-text {
      line-height: 28px;
      text-align: center;
      width: 55%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &-zoom {
    // position: fixed;
    // z-index: 111;
    // top: 7%;
    // left: 34%;
    // padding: 5px;
    &__swiper {
      // position: absolute;
      // left: 30%;
      z-index: 112;
      // top: 10%;
    }
    &__close {
      cursor: pointer;
      display: flex;
      justify-content: end;
      img {
        width: 20px;
        margin-bottom: 10px;
      }
    }
    &__img {
      width: 600px;
      object-fit: contain;
      margin: 0 auto;
      display: flex;
    }
  }
  &__slider {
    padding-top: 80px;
    position: relative;
    @media screen and (max-width: $mobile) {
      padding-top: 20px;
    }
    &__card {
      width: 391px;
      height: 457px;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
      &-image {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(60px);
        border-radius: 12px;
        padding: 12px;
        width: 96%;
        @media screen and (max-width: $mobile) {
          width: 110%;
        }
      }
      &-img {
        // max-width: 367px;
        height: 433px;
        border-radius: 12px;
        object-fit: cover;
        object-position: top;
        @media screen and (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }
  &__pageBreak {
    width: 100%;
    // padding: 0px 253px;
  }
  &__mobLine {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1600px) {
  .program {
    &__main {
      &-text {
        width: 75%;
      }
    }
    &__slider {
      &__card {
        width: 261px;
        height: 357px;
        &__image {
          // max-width: 267px;
          // max-height: 333px;
        }
        &-img {
          max-width: 100%;
          max-height: 333px;
        }
      }
    }
  }
}
@media (min-width: 990px) {
  .swiper1 {
    .swiper-slide {
      width: 24.5%;
    }
  }
}
@media (max-width: 990px) {
  .nav {
    width: 88%;
    top: 65%;
    height: 20px;
  }
  .nav2 {
    width: 98%;
    top: 58%;
    .swiper-button-prev {
      left: -6px;
    }
    .swiper-button-next {
      right: 0;
    }
  }
  .info {
    &__image {
      &-main {
        height: 55vh;
        img {
          width: 110%;
        }
      }
    }
  }
  .program {
    &-zoom {
      left: 5%;
      right: 5%;
      top: 2%;
      width: 90%;
      &__img {
        width: 100%;
        padding-bottom: 30px;
      }
    }
    &__main {
      margin-bottom: 20px;
      &-title {
        font-size: 14px;
        text-align: left;
      }
      &-title2 {
        padding: 0;
        font-size: 22px;
        text-align: left;
        margin-bottom: 18px;
      }
      &-text {
        text-align: left;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.02em;
        margin: 0px;
        width: 100%;
      }
    }

    &__content {
      padding: 0px;
    }
    &__slider {
      &__card {
        width: 218px;
        height: 283px;
        &-img {
          height: 290px;
        }
        &-image {
          width: 100%;
        }
      }
    }

    &__mobLine {
      display: block;
      border-radius: 2px;
      border: 1px solid rgba(255, 255, 255, 0.24);
    }
  }
}

@media (max-width: 576px) {
  .swiper-slide {
    // filter: blur(1px);
    &-active {
      filter: unset !important;
    }
  }
}
</style>

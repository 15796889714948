<template>
  <div class="basket1">
        <img :src="item.image" alt="">
        <div class="basket1-right">
            <p class="black px-18 fw600" v-if="item.type_name == 'subscription'">{{ this.$t("sub") }}</p>
            <p class="red px-16" v-html="item.title"></p>
            <!-- <p class="black px-18 fw600">Quickstart guide <br> MIT</p> -->
            <p class="gray px-16" v-if="item.type_name !== 'subscription' && item.type_name !== 'additional'">Формат: PDF-документ</p>
            <p class="gray px-16" v-if="item.type_name == 'additional'">Формат: консультация</p>
        </div>
  </div>
</template>

<script>
export default {
    props: ["item"],
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/platform/main.scss';
.remove {
    cursor: pointer;
}
.basket1 {
    display: flex;
    align-items: center;
    img {
        width: 160px;
        height: 114px;
        margin-right: 18px;
        object-fit: cover;
        border-radius: 12px;
    }
    &-right {
        .black {
            margin-bottom: 14px;
        }
    }
}
@media (max-width: 960px) {
    .basket1 {
        img {
            width: 153px !important;
            height: 86px;
        }
    }
}
</style>
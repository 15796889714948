<template>
  <router-link class="plat-right__item" :to="{path: `/platform/programs/${item.id}`}" v-if="item.status == true">
        <div class="wrap">
            <div class="plat-right__item-top px-16 gray m_none" v-html="item.country"></div>
            <img class="image" :class="{'blur' : item.status == false}" :src="item.image" alt="">
            <!-- <img @click="$store.state.platformModal = true" v-if="item.status == false" class="lock" src="@/assets/images/platform/locked.png" alt=""> -->
            <div class="plat-right__item-bottom">
                <p class="px-14 fw600 black" v-html="item.programName"></p>
                <p class="px-14 gray" v-html="item.university"></p>
            </div>
        </div>
    </router-link>
    <div class="plat-right__item" v-else>
        <div class="wrap" @click="$store.state.platformModal = true">
            <div class="plat-right__item-top px-16 gray m_none" v-html="item.country"></div>
            <img class="image" :class="{'blur' : item.status == false}" :src="item.image" alt="">
            <img v-if="item.status == false" class="lock" src="@/assets/images/platform/locked.png" alt="">
            <div class="plat-right__item-bottom">
                <p class="px-14 fw600 black" v-html="item.programName"></p>
                <p class="px-14 gray">{{ $t("signReq") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["item"],
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/platform/main.scss';
@import '@/assets/styles/platform/platformPage.scss';

</style>
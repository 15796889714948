<template>
    <div class="addProductBack">
        <div class="addProduct">
            <div class="addProduct__top">
                <p class="addProduct__top__title" v-if="isAdd">Добавить покупку</p>
                <p class="addProduct__top__title" v-else>Редактировать покупку</p>
                <p class="addProduct__top__sub" v-if="isAdd">Укажите данные товара и измените покупку пользователю</p>
                <p class="addProduct__top__sub" v-else>Укажите данные товара и измените покупку пользователю</p>
            </div>  
            <div class="line"></div>
            <div class="addProduct__select">
                <div class="customInput">
                    <label>Выберите категорию товара</label>
                    <select>
                        <option>Наставничество</option>
                    </select>
                </div>
            </div>
            <div class="addProduct__block">
                <div class="customInput">
                    <label>Текст основной</label>
                    <input type="text" placeholder="Текст"/>
                </div>
                <div class="customInput">
                    <label>Текст дополн.</label>
                    <input type="text" placeholder="Текст"/>
                </div>
                <p>*Фото файла будет добавлено автоматически</p>
            </div>
            <div class="line"></div>
            <div class="addProduct__buttons">
                <button class="addProduct__buttons__save" v-if="isAdd">Добавить</button>
                <button class="addProduct__buttons__save" v-else>Сохранить</button>
                <button class="addProduct__buttons__delete" @click="closeModal()">Закрыть</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
      'isRedactor', 'isAdd'  
    ],
    methods: {
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
    width: 100%;
}
    .addProductBack{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 999;
        align-items: center;
        background-color: #0000007a;
    }
    .addProduct{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        width: 33%;
        background-color: white;
        padding: 40px 60px;
        border-radius: 25px;
        &__top{
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: center;
            &__title{
                font-size: 32px;
                color: #11415D;
                font-weight: 600;
            }
            &__sub{
                color: #0000008A;
            }
        }
        &__select{
            width: 100%;
        }
        &__block{
            display: flex;
            flex-wrap: wrap;
            gap: 4%;
            row-gap: 10px;
            P{
                color: #0000008A;   
            }
            .customInput{
                width: 48%;
            }
        }
    &__buttons{
        display: flex;
        width: 100%;
        gap: 4%;
        button{
            width: 48%;
            border: none;
            height: 50px;
            border-radius: 10px;
        }
        &__save{
            color: white;
            font-size: 20px;
            background-color: #11415D;
            border: 1px solid #11415D;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #2076a7;
            }
        }
        &__delete{
            background-color: inherit;
            border: 1px solid #0000001A !important;
            color: #0000008A;
            font-size: 20px;
            transition: all 0.5s ease;
            &:hover{
                color: white;
                background-color: #11415D;
                border: 1px solid #11415D;
            }
        }
    }
    }
    
.customInput{
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer !important;
    label{
        position: absolute;
        top: -8px;
        left: 25px;
        background-color: #ffffff; /* Цвет фона, чтобы совпадал с фоном */
        padding: 0 10px;
        font-size: 14px;
        color: #c0c0c0; 
    }
    input, select {
        width: 100%;
        padding: 0px 25px;
        height: 50px;
        border: 1px solid #e0e0e0; /* Цвет рамки */
        border-radius: 8px;
        font-size: 20px;
        background-color: inherit;
        color: #000; /* Цвет текста */
        outline: none;
    }
    }
</style>